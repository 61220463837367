@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@500;600;700&display=swap');
* {
  font-family: 'Athiti', sans-serif;
}
#registerButton {
  background-color: #07377c;
  color: white;
  width: 100%;
}

#register {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
}
#register-image {
  width: 50vw;
}
#register-form {
  width: 40vw;
}
#register-image-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
}

#register form {
  margin-left: 30px;
}
#register div.dx-form-group-with-caption.dx-form-group.dx-group-colcount-1 {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}

#register-form {
  margin-top: 50px;
}
#mobile-screen-layout-img-register {
  width: 0;
  height: 0;
  background: url('../Images/SlozeniArtikal.JPG');
  background-size: cover;
}

@media only screen and (max-width: 1553px) {
  #register-image-absolute img {
    height: 0vh;
  }
  #register-image {
    width: 0;
  }
  #register-image-absolute {
    width: 0;
  }
  #register form {
    width: 80vw;
  }
  #register {
    justify-content: flex-start;
  }
  #mobile-screen-layout-img-register {
    width: 100%;
    height: 20vh;
  }
  .dark-mode #register-form {
    margin-left: 5vw;
  }
  .dark-mode #register {
    width: 100vw !important;
    height: 220% !important;
  }
  .def {
    right: 15vw !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .def li {
    margin-right: 5px !important;
  }
}
.dark-mode #register {
  height: 180vh;
  width: 99vw;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 850px) {
  .dark-mode #register {
    width: 100vw !important;
    height: 200% !important;
  }
  #register span,
  #register a {
    font-size: 12px !important;
  }
  #register label {
    width: 45vw !important;
  }
}
.dodatna_pitanja {
  width: 100%;
}
