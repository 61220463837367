#login-page {
  display: flex;
  flex-direction: row;
  height: 90vh;
  width: 100%;
  justify-content: center;
}
#login-page * {
  font-weight: 600;
}

#login-page h1 {
  font-weight: 800;
}
#login-form {
  margin-top: 50px;
  width: 40vw !important;
}
#login-form .dx-field-label,
#login-image {
  font-family: 'Athiti', sans-serif;
  width: 35vw;
  height: auto;
}
#login-image {
  width: 50vw;
  height: 100%;
  /* background: url("./Images/LoginBackground.jpg");*/
  background-size: cover;
  text-align: center;
}
#login-image * {
  color: white;
  font-family: 'Athiti', sans-serif;
}
#login-image-absolute {
  position: absolute;
  top: 0;
  width: 50vw;
  height: 100%;
}
#login-image-title-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40vw;
}
#login-image-title-2 {
  width: 30vw;
  position: absolute;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 8vw;
  top: 45%;
}
#login-image-title-2 h1 {
  font-size: 46px;
  font-weight: 700;
  text-align: center;
  color: white;
  font-family: 'Athiti', sans-serif;
}

#login-image-title-2 h2 {
  font-size: 26px;
  text-align: center;
  font-weight: 700;
  color: white;
  font-family: 'Athiti', sans-serif;
  margin-top: 10px;
}
#login-form {
  margin-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#login-form-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#login-form-logo img {
  width: 120px;
  height: 100px;
}
#login-form-email {
  margin-bottom: 30px;
}
.caption-login-form h1 {
  font-size: 34px;
  text-align: justify-all;
  font-weight: 800;
  padding-bottom: 0;
  margin-bottom: 0;
}
.grey-text-login {
  font-size: 14px;
  color: rgb(143, 140, 140);
  padding-top: 0;
  margin-top: 30px !important;
  margin-bottom: -30px !important;
}
div.dx-button-content {
  letter-spacing: 0.1em;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#login-form-btn-submit {
  margin-top: 40px;
  width: 40vw;
  background-color: #07377c;
  padding: 0;
  height: 5vh;
  padding-top: 3px;
  font-size: 14px;
}

.grey-text-login {
  font-weight: 300;
}

div.dx-texteditor-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
input.dx-texteditor-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#login-form .dx-field-value-static,
.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: 100%;
}

#mobile-screen-layout-img-login {
  width: 0;
  height: 0;
  background: url('../Images/SlozeniArtikal.JPG');
  background-size: cover;
  margin-bottom: 30px;
}
#login > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#login {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#i_s {
  position: absolute;
  bottom: 2px;
  z-index: 1000;
  left: 50.2vw;
  margin-left: 50px;
}

@media only screen and (max-width: 1400px) {
  .caption-login-form h1 {
    font-size: 32px;
  }
  #login-image-absolute {
    height: 100%;
  }
  #login-image-title-2 {
    top: 35%;
  }
  #login-image-title-2 h1 {
    font-size: 36px;
  }
  #login-image-title-2 h2 {
    font-size: 30px;
  }
  #login .dx-field-value {
    margin-top: 2vh;
  }
}

@media only screen and (max-width: 1250px) {
  .caption-login-form h1 {
    font-size: 28px;
  }
  #login-image-title-2 h1 {
    font-size: 32px;
  }
  #login-image-title-2 h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 1100px) {
  .caption-login-form h1 {
    font-size: 24px;
  }
  #login-image-title-2 h1 {
    font-size: 28px;
  }
  #login-image-title-2 h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 950px) {
  .caption-login-form h1 {
    font-size: 22px;
  }
  #login-image-title-2 h1 {
    font-size: 22px;
  }
  #login-image-title-2 h2 {
    font-size: 16px;
  }
  #login-image,
  #login-image-absolute {
    width: 0;
    height: 0;
  }
  #login-image-title-2 {
    width: 0;
    height: 0;
  }
  #login-image-title-2 h2,
  #login-image-title-2 h1 {
    font-size: 0;
  }
  #mobile-screen-layout-img-login {
    width: 100%;
    height: 20vh;
    margin-top: 15px;
  }
  #login > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  #login-form {
    margin-left: 20px;
    padding-right: 20px;
    width: 80vw !important;
  }
  #i_s {
    left: 5px !important;
    margin-left: 11vw;
  }
  #login .dx-field-value {
    margin-top: 0;
  }
  .CookieConsent {
    height: 10vh !important;
  }
}

.dark-mode #i_s i,
.dark-mode #login a,
.dark-mode #login i {
  color: rgb(82, 86, 95) !important;
}
.light-mode #i_s i,
.light-mode #login a,
.dark-mode #login i {
  color: rgb(170, 173, 180) !important;
}
@media only screen and (max-width: 850px) {
  .caption-login-form h1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 700px) {
  .caption-login-form h1 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 631px) {
  .caption-login-form h1 {
    font-size: 14px;
  }
  .caption-login-form h2 {
    font-size: 12px;
  }
}
@media only screen and (max-width: 551px) {
  #login i {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 324px) {
  .caption-login-form h1 {
    font-size: 12px;
  }
  .caption-login-form h2 {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1530px) {
  #login-form {
    margin-top: 20vh;
  }
  #login form {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 1100px) {
  #login-form {
    margin-top: 33vh;
  }
}
/*
@media only screen and (max-width: 563px) {
  input {
    font-size: 12px;
  }
  .caption-login-form h1 {
    font-size: 12px;
  }
  .caption-login-form h2 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 467px) {
  input {
    font-size: 10px;
  }
  .caption-login-form h1 {
    font-size: 10px;
  }
  .caption-login-form h2 {
    font-size: 8px;
  }
}

@media only screen and (max-width: 467px) {
  input {
    font-size: 8px;
  }
  .caption-login-form h1 {
    font-size: 8px;
  }
  .caption-login-form h2 {
    font-size: 6px;
  }
}
*/
#login {
  height: 100% !important;
}
@media only screen and (max-height: 800px) {
  #login {
    height: 760px !important;
  }
  #login h3 {
    margin: 0 !important;
  }
}
