#tabela-povrsina-2 .dx-datagrid {
  width: 36vw;
}
#modal {
  margin: 20px;
}
#float-buttons {
  display: flex;
  justify-content: space-around;
}

.g_tijela {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}
.geom-tijelo {
  display: flex;
  flex-direction: row;
  width: 10vw;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

  margin: 20px;
  background-color: white;
}
.geom-tijelo > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
#modal_img {
  width: 9vw;
  height: 11vh;
}

@media only screen and (max-width: 991px) {
  #modal-povrsina {
    width: 90%;
  }
  #modal-povrsina * {
    font-size: 10px;
  }
  #modal-povrsina .popup-item {
    margin-left: 10px !important;
  }
}
@media only screen and (max-width: 755px) {
  #modal-povrsina div.dx-datagrid {
    width: 40vw;
  }
}
@media only screen and (max-width: 680px) {
  #modal-povrsina {
    flex-direction: column !important;
    width: 100% !important;
  }
  #modal-povrsina div.dx-datagrid,
  #modal-left-container {
    width: 100% !important;
  }

  #modal-povrsina img {
    width: 30px;
    height: 30px;
  }
  .geom-tijelo {
    margin: 5px;
    padding: 5px;
  }
  #modal-povrsina div.dx-button {
    width: 10%;
    height: 10%;
    justify-content: center;
    padding-left: 3px;
  }
  #modal-povrsina div.dx-button * {
    width: 90%;
    height: 90%;
  }
  #modal_img {
    width: 13vw !important;
    height: 10vh !important;
  }

  #modal-left-container-inputs {
    margin-left: 0;
    width: 100% !important;
  }
  .povrsina label {
    padding-right: 5px;
  }
  .geom-tijelo {
    width: 25vw !important;
    height: 25vh !important;
    text-align: center;
  }
  .geom-tijelo .dx-field-label {
    padding-left: 5vw !important;
    width: 25vw !important;
  }
}

@media only screen and (max-width: 535px) {
  #modal-povrsina div.dx-button {
    width: 13%;
    height: 13%;
  }
}
@media only screen and (max-width: 441px) {
  #upit .geom-tijelo .dx-field-label {
    font-size: 12px !important;
  }
  #modal-povrsina div.dx-button {
    width: 15%;
    height: 15%;
  }
}
@media only screen and (max-width: 388px) {
  .geom-tijelo {
    height: 20vh !important;
  }
  #upit .geom-tijelo .dx-field-label {
    font-size: 10px !important;
    text-align: center !important;
    padding-left: 3vw !important;
  }
  #modal-povrsina div.dx-button {
    width: 30px;
    height: 30px;
  }
  #float-buttons {
    width: 100% !important;
    margin-left: 0;
  }
}
@media only screen and (max-width: 356px) {
}
/*
@media only screen and (max-width: 625px) {
  #modal-povrsina div.dx-datagrid {
    width: 40vw;
  }
}
@media only screen and (max-width: 565px) {
  #modal-povrsina div.dx-datagrid {
    width: 42vw;
  }
  .g_tijela {
    width: 40vw;
  }
  .geom-tijelo {
    width: 12vw;
  }
  .geom-tijelo * {
    padding-right: 5px;
  }
}
@media only screen and (max-width: 471px) {
  #modal-povrsina div.dx-datagrid {
    width: 36vw;
  }
  #modal-povrsina #modal-left-container-inputs,
  #modal-povrsina #float-buttons {
    margin-left: -10px !important;
    width: 40vw !important;
  }
}

@media only screen and (max-width: 447px) {
  #modal-povrsina div.dx-datagrid {
    width: 38vw;
  }
  .geom-tijelo {
    width: 13vw;
  }
}
@media only screen and (max-width: 405px) {
  .geom-tijelo {
    width: 16vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  #modal-povrsina .naziv-geom-tijela {
    font-size: 9px !important;
  }
  #modal_img {
    width: 9vw !important;
    height: 8vh !important;
  }
}


*/

#modal-left-container-inputs,
#float-buttons {
  width: 70%;
}
.geom-tijelo {
  background-color: white !important;
}
.dark-mode .geom-tijelo {
  background-color: black !important;
  z-index: 100 !important;
}
