#korisnik_panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 1600px;
  z-index: -99;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 100)),
    url('../Images/Background.png');
}
.light-mode #korisnik_panel {
  background: linear-gradient(
      to bottom,
      rgba(205, 205, 205, 0.5),
      rgba(205, 205, 205, 0.7)
    ),
    url('../Images/Background.png');
}

#k_p_h {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  backdrop-filter: blur(12.5px);
}
#korisnik_panel_header {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#k_p_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#korisnik_panel_header #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: right;
  margin-right: 100px !important;
}
#korisnik_panel_header img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
#lista_upita {
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0;
  font-size: 22px;
}
#lista_upita .dx-datagrid-search-panel.dx-searchbox {
  width: 55vw !important;
}

#lista_upita .dx-datagrid .dx-gridbase-container {
  z-index: 1000 !important;
}
/*
#lista_upita td#dx-col-7.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-8.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-9.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-10.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-11.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-12.dx-datagrid-action.dx-cell-focus-disabled {
  background-color: #07377c;
  color: white;
  font-size: 16px;
}*/
#lista_upita_btn {
  width: 100%;
  margin-bottom: 10px;
}
#lista_upita_btn div.dx-button-content {
  background-color: #07377c;
  font-size: 18px;

  padding: 2px 10px;
}
#uredi_upit_td {
  text-align: center;
}
#korisnik_panel_lu {
  width: 100%;
  height: 100vh;
}
#l_u_header {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 32px;
}

#korisnik_panel_images {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 50px;
}
#korisnik_panel_images img {
  border-radius: 10px;
}
#korisnik_panel_images {
  margin: auto 6vw;
  width: 90vw;
}
#lista_upita .dx-datagrid {
  max-width: 55vw;
}

@media only screen and (max-width: 1510px) {
  #lista_upita * {
    font-size: 12px;
  }
  #l_u_header {
    font-size: 20px;
  }
  #number p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1500px) {
  #korisnik_panel_images img {
    width: 90%;
    height: 90%;
  }
  #korisnik_panel_header * {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1400px) {
  #lista_upita div.dx-datagrid {
    max-width: 68vw;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 68vw !important;
  }
  #korisnik_panel_header {
    margin: auto 16vw !important;
  }
  #korisnik_panel_header img {
    margin: auto 0;
    margin-left: 0;
  }
  #korisnik_panel_header p {
    margin-right: 2vw;
  }
}
@media only screen and (max-width: 1201px) {
  #lista_upita * {
    font-size: 11px;
  }
  #lista_upita .dx-datagrid {
    max-width: 90vw;
  }
  #l_u_header {
    font-size: 18px;
  }
  #number p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1040px) {
  #lista_upita * {
    font-size: 10px;
  }

  #l_u_header {
    font-size: 16px;
  }
  #number p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1100px) {
  #lista_upita div.dx-datagrid {
    max-width: 70vw;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 70vw !important;
  }
}

@media only screen and (max-width: 967px) {
  #lista_upita * {
    font-size: 9px;
  }

  #l_u_header {
    font-size: 14px;
  }
  #number p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 953px) {
  #lista_upita #uredi_upit_td {
    text-align: left;
    padding-right: 3px !important;
  }
  /* #lista_upita div.dx-datagrid {
    max-width: auto;
  }*/
}

@media only screen and (max-width: 900px) {
  #korisnik_panel #logout_btn {
    top: 7px !important;
  }
  #korisnik_panel #logout_btn img {
    height: 2vh !important;
  }
}
@media only screen and (max-width: 801px) {
  #lista_upita {
    width: 100vw !important;
  }
  #lista_upita > div {
    margin-left: -10vw !important;
  }
  #korisnik_panel_header {
    margin: auto 10vw !important;
    margin-left: 5vw !important;
  }
  .jkl li {
    margin-left: 45px !important;
  }
  #korisnik_panel_header #logout_btn {
    right: 12vw !important;
  }
}
@media only screen and (max-width: 698px) {
  #korisnik_panel_header {
    margin: auto 10vw !important;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 70vw !important;
  }
  #lista_upita #uredi_upit_td {
    text-align: center;
    padding-right: 3px !important;
  }
  #lista_upita .dx-datagrid {
    min-width: 70vw !important;
  }
  #lista_upita > div {
    margin-left: -5vw !important;
  }
}
@media only screen and (max-width: 686px) {
  #lista_upita * {
    font-size: 8px;
  }
  #lista_upita input * {
    font-size: 12px;
  }
  #l_u_header {
    font-size: 13px;
  }
  #number p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 617px) {
  #lista_upita #uredi_upit_td {
    text-align: left;
  }
  #korisnik_panel_header #number p {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 588px) {
  #korisnik_panel_header #number {
    justify-content: space-around !important;
  }
  #korisnik_panel_header {
    margin: auto 10vw !important;
    margin-left: 5vw !important;
  }
  #korisnik_panel #logout_btn {
    right: 10vw !important;
  }
}

@media only screen and (max-width: 558px) {
  #lista_upita * {
    font-size: 7px;
  }

  #l_u_header {
    font-size: 12px;
  }

  #korisnik_panel_header #number p {
    font-size: 12px;
  }
  #korisnik_panel_header {
    height: 55px;
  }
  #lista_upita div.dx-datagrid {
    max-width: 97vw;
  }
}
#lista_upita > div #gridContainer {
  height: auto !important;
}
@media only screen and (max-width: 492px) {
  #korisnik_panel_header #number {
    margin-right: 50px !important;
  }
  #korisnik_panel_header #logout_btn {
    right: 2px !important;
    top: 7px !important;
  }
  #korisnik_panel_header #logout_btn img {
    width: 14px !important;
  }
  #lista_upita div.dx-datagrid {
    max-width: 80vw;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 80vw !important;
  }
  #lista_upita > div {
    margin-left: -10vw !important;
  }
  #lista_upita * {
    font-size: 8px;
  }

  #l_u_header {
    font-size: 12px;
  }
  #uredi_upit_td {
    text-align: justify;
  }
  #lista_upita div.dx-texteditor-input-container,
  #lista_upita input.dx-texteditor-input {
    height: 3vh;
    font-size: 10px;
  }
}
@media only screen and (max-width: 443px) {
  #lista_upita * {
    font-size: 5px;
  }

  #l_u_header {
    font-size: 12px;
  }
  #lista_upita > div {
    margin-left: -8vw !important;
  }
}
@media only screen and (max-width: 403px) {
  #lista_upita * {
    font-size: 4px;
  }

  #l_u_header {
    font-size: 12px;
  }
}

@media only screen and (max-width: 357px) {
  #korisnik_panel_header #number {
    margin-right: 40px !important;
  }
}
@media only screen and (max-width: 345px) {
  #korisnik_panel_header #number {
    margin-right: 35px !important;
    margin-top: 20px !important;
  }
  #korisnik_panel_header #number p {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 296px) {
  #korisnik_panel_header #number {
    margin-right: auto !important;
    margin-top: 20px !important;
  }
  #korisnik_panel_header #number p {
    font-size: 10px !important;
  }
}
#lista_upita
  div.dx-datagrid-headers
  .dx-datagrid-table
  .dx-row.dx-header-row
  td {
  background-color: #07377c;
  color: white;
}

#gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

.dark #gridContainer tr.notes-row td {
  color: #777;
}

#lista_upita #gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

#lista_upita .dark #gridContainer tr.notes-row td {
  color: #777;
}

#lista_upita #gridContainer tbody.employee:hover {
  background-color: #ebebeb;
}

#lista_upita .dark #gridContainer tbody.employee:hover {
  background-color: #484848;
}

#lista_upita #gridContainer tbody.employee:hover tr.main-row td {
  color: #000;
}

#lista_upita .dark #gridContainer tbody.employee:hover tr.main-row td {
  color: #ccc;
}

#lista_upita #gridContainer tbody.employee:hover tr.notes-row td {
  color: #888;
}

#lista_upita .dx-toolbar .dx-toolbar-items-container {
  height: 30px;
}
.light-mode #lista_upita div.dx-datagrid-pager {
  background-color: rgba(245, 239, 239, 0.9) !important;
}

#lista_upita .dx-datagrid .dx-texteditor-input {
  border-radius: 0 !important;
}
#lista_upita #gridContainer tbody.employee:hover tr.main-row td {
  background-color: rgba(7, 55, 124, 0.4);
  color: #fff;
}
.light-mode #lista_upita .dx-datagrid .dx-row-alt > td,
.light-mode .dx-datagrid .dx-row-alt > tr > td {
  background-color: rgba(245, 239, 239, 0.9) !important;
}
#lista_upita .dx-datagrid-search-panel {
  border: none !important;
}
.light-mode #lista_upita .dx-datagrid-search-panel input {
  background-color: rgba(245, 239, 239, 0.9) !important;
}
