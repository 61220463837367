@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Athiti:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Athiti:wght@500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Athiti:wght@500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.dx-field-label,
.dx-field-value {
  font-size: 18px;
}

body {
  margin: 30px;
}

form {
  margin: 10px;
}

#logout_btn {
  position: absolute;
  right: 18vw;
  top: 7px;
  z-index: 9999;
  border: none;
  outline: none;
  background-color: transparent;
}
#admin_panel_lu {
  margin-left: 15vw;
}
.register-login {
  margin-right: 13vw;
  display: flex;
  justify-content: flex-end;
}
.register-login * {
  margin-left: 70px;
  margin-right: 2px;
  width: 20px;
  height: 10px;
  list-style-type: none;
}

.languages * {
  margin-left: 5px;
  width: 30px;
  height: 15px;
  list-style-type: none;
  /*position: absolute;*/
  margin-right: 10px;
}
.nav-lng {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
}
.languages {
  margin-right: 45px;
  top: 25px;
  position: fixed;
  z-index: 999999;
  right: 22vw;
}
#ul_lng {
  display: flex;
}
.dx-field {
  margin-top: 50px;
}

.select-with-image div {
  display: inline-flex;
  width: 90%;
}

.select-with-color {
  display: flex;
  justify-content: flex-start;
}

.product-name {
  width: 80%;
}
.image-field {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.images {
  margin-left: 5px;
  margin-top: 2px;
  height: 30px;
  width: 30px;
}
#SI {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url(/static/media/natur.81cb017d.JPG);
}

#ZL {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url(/static/media/zlatna.fcf8f818.JPG);
}

#TI {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url(/static/media/titan.cb1a5d1b.JPG);
}
#CV {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url(/static/media/crvena.6c094c4a.JPG);
}

#PL {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url(/static/media/plava.42516a2b.JPG);
}

#ZE {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url(/static/media/zelena.701bed23.jpg);
}

#NA {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url(/static/media/narandzasta.8c2888fe.JPG);
}

#CR {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url(/static/media/crna.b6aeccb7.JPG);
}

.povrsina {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.dx-field-label {
  width: auto;
}
.povrsina div {
  display: flex;
}
label {
  margin: 8px 5px auto;
  color: rgb(51, 122, 183);
}

.error {
  position: absolute;
  margin-left: 30vw;
  text-align: center;
  font-size: 40px;
  color: rgb(12, 77, 88);
}

.dobijenaCijena {
  display: flex;
}

.list-opis {
  list-style-type: none;
  padding-left: 3px !important;
}
.list-opis-hppe {
  list-style-type: none;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around;
  font-size: 14px;
  height: 12px;
  width: 25vw !important;
}
.list-opis-hppe li {
  margin-left: 1px;
}
.opis,
.list-opis-hppe {
  text-align: left;
  margin-top: 1px;
}
.opis {
  font-size: 14px;
}
.boja-opis {
  margin-left: -50px;
}
.btns {
  width: 100vw;
  height: 30px;
}

.star {
  color: red;
}

.switch-div {
  display: flex;
  justify-content: flex-end;
}

#error_page h1,
h2 {
  font-family: 'Cinzel', serif;
  color: rgb(165, 116, 9);
}

#error h1,
h2 {
  font-family: 'Cinzel', serif;
  color: rgb(165, 116, 9);
}

.cijena-component {
  margin: auto 20vw;
}
.upit-component {
  padding-bottom: 5px;
}

.list-upit {
  list-style-type: none;
}
.list-upit * {
  padding: 15px;
  font-size: 16px;
}
.list-upit h2 {
  font-family: 'Cinzel', serif;
  font-size: 20px;
  color: rgb(48, 61, 87);
  border-bottom: 1px solid gold;
}
.list-upit-details li {
  color: black;
}

.dx-tabs .dx-tab,
.dx-tabs .dx-button-content {
  background-color: rgb(253, 255, 255);
}

#go-to-upit {
  float: right;
  height: 5vh;
  width: 10vw;
  font-size: 18px;
}

.cijena-prepare {
  height: 30vh;
}

.silings * {
  margin-left: 10px;
}

.home-page * {
  text-align: center;
  font-family: 'Cinzel', serif;
  font-size: 40px;
  color: rgb(48, 61, 87);
  border-bottom: 1px solid gold;
}

.dx-datagrid-headers {
  color: black;
  font-weight: bold;
}

.dx-datagrid .dx-row > td {
  font-family: Arial, Helvetica, sans-serif;
}

.dx-datagrid {
  width: 80vw;
}

.dx-dropdowneditor-icon {
  height: 1000%;
}
@media only screen and (max-width: 1700px) {
  #admin_panel_lu {
    margin-left: 0;
  }
}
@media only screen and (max-width: 900px) {
  .dx-field-label,
  .dx-field-value {
    font-size: 12px;
  }
  #logout_btn {
    top: 2vh;
  }
  .languages li img {
    margin-left: 2px;
    width: 25px;
    height: 10px;
  }
  h1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  h1 {
    font-size: 16px;
  }
  #logout_btn {
    top: 2vh;
    padding-left: 0;
  }
}
#checkSelectAll.dx-checkbox-text {
  color: red;
}

.dx-checkbox-text {
  font-size: 16px;
  padding: 0;
  padding-left: 25px;
}

.list-group {
  display: flex;
  flex-direction: column;
}

.list-group-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.list-group-item * {
  width: 50%;
}

.image {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background-color: rgb(0, 255, 0);
}

.k-button {
  color: white;
  background-color: rgb(51, 122, 183);
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px 18px 8px;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

#modal-povrsina {
  display: flex;
  justify-content: space-between;
}
#tabela-povrsina {
  width: 100% !important;
  margin-right: 20px;
}

.naziv-geom-tijela {
  font-size: 14px;
}
#tabela-povrsina {
  width: 40vw;
}
#tabela-povrsina-1 {
  display: flex;
}

.adminPanelGrid .dx-datagrid {
  width: 60vw;
}

#draw {
  margin: 30px;
  width: 100%;
  height: 100%;
}
#draw input {
  font-size: 18px;
}
#draw Button {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 12px;
}
.custom-icon {
  width: 20vw;
  height: 17vh;
}
.neispravni_podaci {
  color: #d9534f;
  border-bottom: 1px dashed #d9534f;

  width: -webkit-max-content;

  width: max-content;
}

/*lightbox */
.lightbox-btn-close {
  left: auto !important;
  right: 0 !important;
  width: 85px !important;
}
.lightbox-btn-close svg {
  width: 75px !important;
  height: 75px !important;
}
.lightbox-btn-close svg path {
  d: path(
    'M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z'
  ) !important;
}

.opis {
  width: 25vw !important;
}

.dos_list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 25vw;
}
/*

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle !important;
}
*/

/* 23.06. */
.np_opis {
  background-color: white;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

#uslovi_app {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
/*dark mode */
.react-switch-bg {
  background: rgb(136, 136, 136) !important;
}
.dark-mode,
.dark-mode #login,
.dark-mode #register,
.dark-mode #korisnik_panel,
.dark-mode #lista_upita .dx-datagrid {
  background-color: #353434;
}
.dark-mode #upit,
.dark-mode #s_a,
.dark-mode #error_page,
.dark-mode #error,
.dark-mode #uslovi_app {
  background: linear-gradient(
      to bottom,
      rgba(10, 10, 10, 0.6),
      rgba(0, 0, 0, 100)
    ),
    url(/static/media/Background.d728f297.png) !important;
  background-size: cover;
  width: 100% !important;
  z-index: -99;
}

#app {
  width: 100vw;
  height: 100vh;
}

#app .dark-mode {
  background-color: black;
}
.light-mode #upit,
.light-mode #s_a,
.light-mode #lista_upita .dx-datagrid,
.light-mode #error_page,
.light-mode #error,
.light-mode #uslovi_app {
  background: linear-gradient(
      to bottom,
      rgba(205, 205, 205, 0.5),
      rgba(205, 205, 205, 0.7)
    ),
    url(/static/media/Background.d728f297.png) !important;
  width: 100% !important;
  height: 100% important;
  background-size: cover;
}
.light-mode #s_a {
  width: 100vw !important;
  height: 100% !important;
}
.dark-mode .np_opis {
  background-color: #353434;
}
.dark-mode #upit .card,
.dark-mode #s_a .card,
.dark-mode #upit .dx-checkbox,
.dark-mode #upit .dx-checkbox .dx-checkbox-icon,
.dark-mode #register .dx-checkbox,
.dark-mode #register .dx-checkbox .dx-checkbox-icon,
.dark-mode
  #register
  div.dx-form-group-with-caption.dx-form-group.dx-group-colcount-1 {
  background-color: #353434;
}
.dark-mode
  #register
  div.dx-form-group-with-caption.dx-form-group.dx-group-colcount-1 {
  box-shadow: 0 0 3px rgba(250, 250, 250, 0.4);
}
.dark-mode #s_a {
  position: absolute;
  width: 100vw;
  height: 1050px;
}
.dark-mode #s_a h2 {
  color: white !important;
}

.dark-mode #upit {
  height: 1800px;
}
.modal_shown {
  height: 2300px;
}
.dark-mode #upit {
  border-bottom: 130px black solid;
}
.dark-mode * {
  color: white;
}
.dark-mode .dx-texteditor-input,
.dark-mode .dx-show-invalid-badge {
  background-color: #464444;
}
.dark-mode .dx-texteditor-buttons-container {
  background-color: #464444;
}
.dark-mode #login-form-btn-submit {
  background-color: #07377c;
}

.dark-mode .login-textbox {
  outline: none !important;
}
.dark-mode #register-form {
  margin-left: -10vw;
}

.dark-mode #register-form form {
  margin-top: 40px !important;
}
.dark-mode #register-image-absolute {
  height: 55% !important;
}
.dark-mode #lista_upita .dx-datagrid .dx-row-alt > td,
.dark-mode #lista_upita .dx-datagrid .dx-row-alt > tr > td {
  background-color: rgb(85, 85, 88);
  border-color: rgb(85, 85, 88);
}

.dark-mode .dx-datagrid,
.dark-mode #register_wait {
  background-color: #39383b;
}

.light-mode .dx-datagrid .main-row {
  background-color: #b8b7b9 !important;
}
.dark-mode
  #lista_upita
  div.dx-datagrid-headers
  .dx-datagrid-table
  .dx-row.dx-header-row
  td {
  background-color: #07377c;
  color: white;
  border-bottom: none;
}
.dark-mode .blur {
  background-color: rgba(205, 205, 205, 0.3);
}

.dark-mode #k_p_h,
.dark-mode #k_p_h_u,
.dark-mode #k_p_h_sa {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(8px);
  box-shadow: inset 0 0 40px 15px hsl(0deg 0% 100% / 20%);
}

.dark-mode #lista_upita #gridContainer tbody.employee:hover {
  background-color: #39383b;
}
.dark-mode #register .dx-button {
  background-color: #07377c;
}
.dark-mode .dx-button,
.dark-mode .k-button {
  border: 0.3px solid white !important;
}
.opis *,
.m_opis {
  font-weight: 400;
}
.opis b {
  font-weight: 800;
}

.light-mode .progress * {
  color: black !important;
}
.progress-bar {
  background-color: #07377c !important;
}
.dark-mode div.progress {
  background: transparent !important;
}
.dark-mode #upit .slijepe_rupe {
  color: white;
}

.dark-mode #eye-icon-btn div.dx-button {
  border: none !important;
}

.dark-mode #back {
  color: white !important;
}

/* za sliku ako bude dodana kao pozadina upit komponente
.light-mode #upit {
  background: url('./Images/Jednostavno/j2.JPG');
  position: cover;
}*/
#q {
  color: white;
}
.star {
  color: red !important;
}
/*
.dark-mode #lista_upita .dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: rgb(85, 85, 88);
  border-color: rgb(85, 85, 88);
}

.dark-mode .dx-datagrid {
  background-color: #39383b;
}

.dark-mode
  #lista_upita
  div.dx-datagrid-headers
  .dx-datagrid-table
  .dx-row.dx-header-row
  td {
  background-color: #07377c;
  color: white;
  border-bottom: none;
}
*/
.dark-mode .dx-overlay-content,
.dark-mode .dx-toolbar {
  background-color: #353434 !important;
  color: white !important;
}
.dark-mode .dx-overlay-content div.dx-button-content * {
  color: white;
  background-color: #353434 !important;
}
.dark-mode .dx-closebutton {
  outline: none !important;
  border: none !important;
}
.light-mode .dx-overlay-content div.dx-button-content * {
  outline: none !important;
  border: none !important;
  background-color: white !important;
}
.dark-mode .rgst_vldt * {
  color: black !important;
}
.rgst_vldt .dx-button-content * {
  color: white !important;
}

.dark-mode #btn-icon {
  background: transparent !important;
}
.dark-mode
  .dx-field-item-content
  .dx-field-item-content-location-right
  .dx-button {
  background-color: transparent !important;
}
.abc {
  right: 9vw;
  margin-right: 50px !important;
}
.abc li {
  margin-right: 0 !important;
}
.def {
  right: 7vw;
  margin-right: 0 !important;
}
.def li {
  margin-right: 0 !important;
}

.dx-checkbox-icon,
.dx-select-checkboxes-hidden {
  border: 1px rgb(165, 165, 165) solid !important;
}

#upload_draw .dx-toolbar-label {
  max-width: 100% !important;
}

#upload_draw .dx-button-content {
  padding-left: 5vw !important;
}

.infinity-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);

  width: 130px;
  height: 70px;
}

.infinity-loader .bg div,
.infinity-loader > .fg > div > div {
  width: 70px;
  height: 70px;
  border: 10px solid #aaa;
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
}

.infinity-loader .right-bg {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
  left: -10px;
}

.infinity-loader > .fg > div > div {
  border-color: #083ce7 #083ce7 transparent;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  position: static;
}

.infinity-loader > .fg > div {
  clip: rect(0, 70px, 35px, 0);
  position: absolute;
}

.infinity-loader > .fg > .bottom-right-rect {
  left: -10px;
  -webkit-transform: translateX(100%) scale(1, -1);
          transform: translateX(100%) scale(1, -1);
}

.infinity-loader > .fg > .bottom-right-rect > div {
  -webkit-animation-delay: 0.25s;
          animation-delay: 0.25s;
}

.infinity-loader > .fg > .top-right-rect {
  left: -10px;
  -webkit-transform: translateX(100%) scale(-1, 1);
          transform: translateX(100%) scale(-1, 1);
}

.infinity-loader > .fg > .top-right-rect > div {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.infinity-loader > .fg > .bottom-left-rect {
  -webkit-transform: scale(-1);
          transform: scale(-1);
}

.infinity-loader > .fg > .bottom-left-rect > div {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}

.infinity-loader > .fg {
  -webkit-filter: drop-shadow(0 0 6px #083ce7);
          filter: drop-shadow(0 0 6px #083ce7);
}

@-webkit-keyframes spin {
  50%,
  100% {
    -webkit-transform: rotate(495deg);
            transform: rotate(495deg);
  }
}

@keyframes spin {
  50%,
  100% {
    -webkit-transform: rotate(495deg);
            transform: rotate(495deg);
  }
}

#loading {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
}

.container {
  position: relative;
}

.container,
.left-content {
  min-height: 530px;
}

.left-content {
  display: inline-block;
  width: 180px;
  padding: 0 10px 10px;
  background-color: rgba(248, 255, 253, 0.15);
  box-shadow: -5px 0px 14px -8px rgba(0, 0, 0, 0.25) inset;
}

.right-content {
  position: absolute;
  right: 0;
  left: 220px;
  top: 0;
  height: 100%;
}

sup {
  font-size: 0.8em;
  vertical-align: super;
  line-height: 0;
}

.right-content .sub-title {
  font-size: 120%;
  color: rgba(152, 152, 152, 0.8);
}

.title-container {
  min-height: 140px;
  margin-bottom: 10px;
}

.title-container .country-name {
  font-size: 240%;
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 10px;
}

.title-container > div:not(.flag) {
  margin-left: 204px;
}

.stats {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}

.stats > div {
  display: table-cell;
  text-align: center;
  border: 1px solid rgba(191, 191, 191, 0.25);
  padding: 20px 0 25px;
  width: 33%;
}

.stats > div:first-child,
.stats > div:last-child {
  border-right-width: 0;
  border-left-width: 0;
}

.stats .stat-value {
  font-size: 200%;
}

#tabpanel {
  margin-top: 10px;
}

#tabpanel .dx-multiview-wrapper {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

#tabpanel .tab-panel-title {
  font-size: 120%;
  font-weight: 500;
}

#tabpanel .dx-multiview-item-content {
  padding: 20px 0 22px;
  min-height: 178px;
  position: relative;
}

#tabpanel .right-content {
  top: 15px;
  left: 202px;
}

#tabpanel .stats {
  width: 398px;
  margin-top: 20px;
  border-top: 1px solid rgba(250, 246, 246, 0.25);
}

#tabpanel .stats > div {
  padding: 7px 0;
  text-align: left;
  border: 0;
}

#tabpanel .stats > div:first-child {
  width: 40%;
}

#tabpanel .stats > div:not(:first-child) {
  width: 30%;
}

.left-content
  .dx-treeview-node.dx-state-selected
  > .dx-item
  > .dx-item-content {
  font-weight: 500;
}

#admin_panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  z-index: -99;
}
.light-mode #admin_panel {
  background-image: linear-gradient(
    to bottom right,
    rgb(252, 252, 252),
    rgb(223, 217, 220)
  );
}
.dark-mode #admin_panel {
  background-image: linear-gradient(
    to bottom right,
    rgb(20, 19, 19),
    rgb(75, 74, 74)
  );
}
#admin_panel_header {
  position: fixed;
  display: block;
  width: 100vw;
  height: 60px;
  z-index: 10;
  background: rgba(243, 239, 239, 0.2);
  -webkit-backdrop-filter: blur(12.5px);
          backdrop-filter: blur(12.5px);
}

.container {
  position: absolute;
  top: 60px;
}
.left-content {
  height: 126vh;
  padding-left: 0;
  position: absolute;
  left: 0;
  background-color: white;
}
.dark-mode .left-content {
  background: rgba(243, 239, 239, 0.2);
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
}

.light-mode #admin_panel #gridDeleteSelected {
  opacity: 1;
  background-color: white;
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 2px;
}
.dark-mode #admin_panel #gridDeleteSelected {
  background-color: #333 !important;
}
#admin_panel span.dx-button-text {
  opacity: 1;
}

#admin_panel
  .dx-treeview-item-without-checkbox.dx-state-focused
  > .dx-treeview-item {
  background-color: black;
}

@media only screen and (max-width: 767px) {
  #admin_panel div.dx-button-content {
    width: 30vw;
    background-color: white;
    font-size: 12px;
  }
}

@media only screen and (max-width: 754px) {
  #admin_panel * {
    font-size: 12px;
  }
}

@media only screen and (max-width: 613px) {
  #admin_panel * {
    font-size: 8px;
    width: 30vw;
  }
}

td {
    padding: 30px;
}
#tabela-prevoda .dx-datagrid {
  width: 100%;
}

#lng_form > div {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  background-color: white;
  width: 45%;
}
.dark-mode #lng_form > div {
  background-color: #464444;
  border: 1px solid white;
}
#lng_form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#lng_img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px dotted gray;
}
#lng_img > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#new_lng_btn,
#end_lng_btn {
  float: right;
  background-color: black;
  color: white;
}
#jezik {
  width: 100%;
}
/*
#tabela-prevoda div.dx-datagrid-header-panel,
#tabela-prevoda
  .dx-toolbar
  .dx-widget
  .dx-visibility-change-handler
  .dx-collection,
#tabela-prevoda .dx-toolbar-items-container {
  background-image: linear-gradient(
    to bottom right,
    rgb(252, 252, 252),
    rgb(223, 217, 220)
  );
}
*/
.dx-editor-cell .dx-texteditor,
.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  background: transparent !important;
}

.lista_podataka {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.lista_podataka div {
  display: flex;
  justify-content: space-between;
}

#profil_podaci {
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}

.chng_pwd_btn div.dx-button {
  margin-top: 8px;
  margin-left: 8px;
  background-color: black;
  color: white;
}
#admin_profil {
  width: 100%;
}
#card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  background-color: white;
}
.dark-mode #admin_profil #card {
  background-color: #464444;
}

#draw {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

#draw > div {
  display: flex;
  justify-content: center;
}

#draw div.dx-button {
  margin-top: 8px;
  margin-left: 8px;
  background-color: black;
  color: white;
}
.dark-mode #draw {
  background-color: #464444;
}

.dark-mode #draw * {
  color: white;
}
.dark-mode #draw input {
  border-bottom: 1px solid white;
}

#liste .dx-datagrid {
  max-width: 60vw;
}

#liste div.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row td {
  background-color: #337ab7;
  color: white;
}

#gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

.dark #gridContainer tr.notes-row td {
  color: #777;
}

#liste #gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

#liste .dark #gridContainer tr.notes-row td {
  color: #777;
}

#liste #gridContainer tbody.employee:hover {
  background-color: #ebebeb;
}

#liste .dark #gridContainer tbody.employee:hover {
  background-color: #484848;
}

#liste #gridContainer tbody.employee:hover tr.main-row td {
  color: #000;
}

#liste .dark #gridContainer tbody.employee:hover tr.main-row td {
  color: #ccc;
}

#liste #gridContainer tbody.employee:hover tr.notes-row td {
  color: #888;
}

#liste .dx-toolbar .dx-toolbar-items-container {
  height: 30px;
}

.dark-mode #liste .dx-datagrid .dx-row-alt > td,
.dark-mode #liste .dx-datagrid .dx-row-alt > tr > td {
  background-color: rgb(85, 85, 88);
  border-color: rgb(85, 85, 88);
}

#korisnik_panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 99vw;
  height: 1600px;
  z-index: -99;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 100)),
    url(/static/media/Background.d728f297.png);
}
.light-mode #korisnik_panel {
  background: linear-gradient(
      to bottom,
      rgba(205, 205, 205, 0.5),
      rgba(205, 205, 205, 0.7)
    ),
    url(/static/media/Background.d728f297.png);
}

#k_p_h {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  -webkit-backdrop-filter: blur(12.5px);
          backdrop-filter: blur(12.5px);
}
#korisnik_panel_header {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#k_p_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#korisnik_panel_header #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: right;
  margin-right: 100px !important;
}
#korisnik_panel_header img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
#lista_upita {
  padding-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0;
  font-size: 22px;
}
#lista_upita .dx-datagrid-search-panel.dx-searchbox {
  width: 55vw !important;
}

#lista_upita .dx-datagrid .dx-gridbase-container {
  z-index: 1000 !important;
}
/*
#lista_upita td#dx-col-7.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-8.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-9.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-10.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-11.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-12.dx-datagrid-action.dx-cell-focus-disabled {
  background-color: #07377c;
  color: white;
  font-size: 16px;
}*/
#lista_upita_btn {
  width: 100%;
  margin-bottom: 10px;
}
#lista_upita_btn div.dx-button-content {
  background-color: #07377c;
  font-size: 18px;

  padding: 2px 10px;
}
#uredi_upit_td {
  text-align: center;
}
#korisnik_panel_lu {
  width: 100%;
  height: 100vh;
}
#l_u_header {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 32px;
}

#korisnik_panel_images {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 50px;
}
#korisnik_panel_images img {
  border-radius: 10px;
}
#korisnik_panel_images {
  margin: auto 6vw;
  width: 90vw;
}
#lista_upita .dx-datagrid {
  max-width: 55vw;
}

@media only screen and (max-width: 1510px) {
  #lista_upita * {
    font-size: 12px;
  }
  #l_u_header {
    font-size: 20px;
  }
  #number p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1500px) {
  #korisnik_panel_images img {
    width: 90%;
    height: 90%;
  }
  #korisnik_panel_header * {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 1400px) {
  #lista_upita div.dx-datagrid {
    max-width: 68vw;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 68vw !important;
  }
  #korisnik_panel_header {
    margin: auto 16vw !important;
  }
  #korisnik_panel_header img {
    margin: auto 0;
    margin-left: 0;
  }
  #korisnik_panel_header p {
    margin-right: 2vw;
  }
}
@media only screen and (max-width: 1201px) {
  #lista_upita * {
    font-size: 11px;
  }
  #lista_upita .dx-datagrid {
    max-width: 90vw;
  }
  #l_u_header {
    font-size: 18px;
  }
  #number p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1040px) {
  #lista_upita * {
    font-size: 10px;
  }

  #l_u_header {
    font-size: 16px;
  }
  #number p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1100px) {
  #lista_upita div.dx-datagrid {
    max-width: 70vw;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 70vw !important;
  }
}

@media only screen and (max-width: 967px) {
  #lista_upita * {
    font-size: 9px;
  }

  #l_u_header {
    font-size: 14px;
  }
  #number p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 953px) {
  #lista_upita #uredi_upit_td {
    text-align: left;
    padding-right: 3px !important;
  }
  /* #lista_upita div.dx-datagrid {
    max-width: auto;
  }*/
}

@media only screen and (max-width: 900px) {
  #korisnik_panel #logout_btn {
    top: 7px !important;
  }
  #korisnik_panel #logout_btn img {
    height: 2vh !important;
  }
}
@media only screen and (max-width: 801px) {
  #lista_upita {
    width: 100vw !important;
  }
  #lista_upita > div {
    margin-left: -10vw !important;
  }
  #korisnik_panel_header {
    margin: auto 10vw !important;
    margin-left: 5vw !important;
  }
  .jkl li {
    margin-left: 45px !important;
  }
  #korisnik_panel_header #logout_btn {
    right: 12vw !important;
  }
}
@media only screen and (max-width: 698px) {
  #korisnik_panel_header {
    margin: auto 10vw !important;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 70vw !important;
  }
  #lista_upita #uredi_upit_td {
    text-align: center;
    padding-right: 3px !important;
  }
  #lista_upita .dx-datagrid {
    min-width: 70vw !important;
  }
  #lista_upita > div {
    margin-left: -5vw !important;
  }
}
@media only screen and (max-width: 686px) {
  #lista_upita * {
    font-size: 8px;
  }
  #lista_upita input * {
    font-size: 12px;
  }
  #l_u_header {
    font-size: 13px;
  }
  #number p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 617px) {
  #lista_upita #uredi_upit_td {
    text-align: left;
  }
  #korisnik_panel_header #number p {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 588px) {
  #korisnik_panel_header #number {
    justify-content: space-around !important;
  }
  #korisnik_panel_header {
    margin: auto 10vw !important;
    margin-left: 5vw !important;
  }
  #korisnik_panel #logout_btn {
    right: 10vw !important;
  }
}

@media only screen and (max-width: 558px) {
  #lista_upita * {
    font-size: 7px;
  }

  #l_u_header {
    font-size: 12px;
  }

  #korisnik_panel_header #number p {
    font-size: 12px;
  }
  #korisnik_panel_header {
    height: 55px;
  }
  #lista_upita div.dx-datagrid {
    max-width: 97vw;
  }
}
#lista_upita > div #gridContainer {
  height: auto !important;
}
@media only screen and (max-width: 492px) {
  #korisnik_panel_header #number {
    margin-right: 50px !important;
  }
  #korisnik_panel_header #logout_btn {
    right: 2px !important;
    top: 7px !important;
  }
  #korisnik_panel_header #logout_btn img {
    width: 14px !important;
  }
  #lista_upita div.dx-datagrid {
    max-width: 80vw;
  }
  #lista_upita .dx-datagrid-search-panel.dx-searchbox {
    width: 80vw !important;
  }
  #lista_upita > div {
    margin-left: -10vw !important;
  }
  #lista_upita * {
    font-size: 8px;
  }

  #l_u_header {
    font-size: 12px;
  }
  #uredi_upit_td {
    text-align: justify;
  }
  #lista_upita div.dx-texteditor-input-container,
  #lista_upita input.dx-texteditor-input {
    height: 3vh;
    font-size: 10px;
  }
}
@media only screen and (max-width: 443px) {
  #lista_upita * {
    font-size: 5px;
  }

  #l_u_header {
    font-size: 12px;
  }
  #lista_upita > div {
    margin-left: -8vw !important;
  }
}
@media only screen and (max-width: 403px) {
  #lista_upita * {
    font-size: 4px;
  }

  #l_u_header {
    font-size: 12px;
  }
}

@media only screen and (max-width: 357px) {
  #korisnik_panel_header #number {
    margin-right: 40px !important;
  }
}
@media only screen and (max-width: 345px) {
  #korisnik_panel_header #number {
    margin-right: 35px !important;
    margin-top: 20px !important;
  }
  #korisnik_panel_header #number p {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 296px) {
  #korisnik_panel_header #number {
    margin-right: auto !important;
    margin-top: 20px !important;
  }
  #korisnik_panel_header #number p {
    font-size: 10px !important;
  }
}
#lista_upita
  div.dx-datagrid-headers
  .dx-datagrid-table
  .dx-row.dx-header-row
  td {
  background-color: #07377c;
  color: white;
}

#gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

.dark #gridContainer tr.notes-row td {
  color: #777;
}

#lista_upita #gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

#lista_upita .dark #gridContainer tr.notes-row td {
  color: #777;
}

#lista_upita #gridContainer tbody.employee:hover {
  background-color: #ebebeb;
}

#lista_upita .dark #gridContainer tbody.employee:hover {
  background-color: #484848;
}

#lista_upita #gridContainer tbody.employee:hover tr.main-row td {
  color: #000;
}

#lista_upita .dark #gridContainer tbody.employee:hover tr.main-row td {
  color: #ccc;
}

#lista_upita #gridContainer tbody.employee:hover tr.notes-row td {
  color: #888;
}

#lista_upita .dx-toolbar .dx-toolbar-items-container {
  height: 30px;
}
.light-mode #lista_upita div.dx-datagrid-pager {
  background-color: rgba(245, 239, 239, 0.9) !important;
}

#lista_upita .dx-datagrid .dx-texteditor-input {
  border-radius: 0 !important;
}
#lista_upita #gridContainer tbody.employee:hover tr.main-row td {
  background-color: rgba(7, 55, 124, 0.4);
  color: #fff;
}
.light-mode #lista_upita .dx-datagrid .dx-row-alt > td,
.light-mode .dx-datagrid .dx-row-alt > tr > td {
  background-color: rgba(245, 239, 239, 0.9) !important;
}
#lista_upita .dx-datagrid-search-panel {
  border: none !important;
}
.light-mode #lista_upita .dx-datagrid-search-panel input {
  background-color: rgba(245, 239, 239, 0.9) !important;
}

/*#admin_upit_panel {
  position: absolute;
  top: 0;
  left: 10;
  width: 100%;
  z-index: -99;
}
#admin_panel_lu {
  margin-left: 27vw;
}
#admin_upit_panel_header {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  backdrop-filter: blur(12.5px);
}

#admin_upit_panel #lista_upita {
  padding-top: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#admin_upit_panel
  #lista_upita
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td,
#admin_upit_panel
  #lista_upita
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > tr
  > td {
  background-color: rgb(250, 251, 255);
}
#lista_upita td#dx-col-7.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-8.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-9.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-10.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-11.dx-datagrid-action.dx-cell-focus-disabled,
#lista_upita td#dx-col-12.dx-datagrid-action.dx-cell-focus-disabled {
  background-color: #07377c;
  color: white;
  font-size: 16px;
}
#lista_upita_btn {
  width: 100%;
  margin-bottom: 50px;
}
#lista_upita_btn div.dx-button-content {
  background-color: black;
  font-size: 22px;
}

#korisnik_panel_lu {
  width: 100%;
  height: 100vh;
}
#l_u_header {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 46px;
}

#korisnik_panel_images {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 50px;
}
#korisnik_panel_images img {
  border-radius: 10px;
}

.admin_l_u_datagrid {
  width: 80%;
}
#admin_panel_lu .dx-datagrid {
  width: 70vw;
  position: absolute;
  left: 0;
  z-index: 1000;
  height: auto;
}
/*
@media only screen and (max-width: 1500px) {
  #admin_panel_lu {
    margin-left: 20vw;
  }
}
@media only screen and (max-width: 1391px) {
  #admin_panel_lu {
    margin-left: 10vw;
  }
}
@media only screen and (max-width: 1276px) {
  #admin_panel_lu {
    margin-left: 5vw;
  }
}
@media only screen and (max-width: 1205px) {
  #admin_panel_lu {
    margin-left: 3vw;
  }
}
@media only screen and (max-width: 1200px) {
  #admin_panel_lu {
    margin-left: 10vw;
  }
}
@media only screen and (max-width: 900px) {
  #admin_panel_lu {
    margin-left: 20vw;
  }
  #admin_panel_lu .dx-datagrid {
    width: 60vw;
  }
}
*/

#login-page {
  display: flex;
  flex-direction: row;
  height: 90vh;
  width: 100%;
  justify-content: center;
}
#login-page * {
  font-weight: 600;
}

#login-page h1 {
  font-weight: 800;
}
#login-form {
  margin-top: 50px;
  width: 40vw !important;
}
#login-form .dx-field-label,
#login-image {
  font-family: 'Athiti', sans-serif;
  width: 35vw;
  height: auto;
}
#login-image {
  width: 50vw;
  height: 100%;
  /* background: url("./Images/LoginBackground.jpg");*/
  background-size: cover;
  text-align: center;
}
#login-image * {
  color: white;
  font-family: 'Athiti', sans-serif;
}
#login-image-absolute {
  position: absolute;
  top: 0;
  width: 50vw;
  height: 100%;
}
#login-image-title-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40vw;
}
#login-image-title-2 {
  width: 30vw;
  position: absolute;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 8vw;
  top: 45%;
}
#login-image-title-2 h1 {
  font-size: 46px;
  font-weight: 700;
  text-align: center;
  color: white;
  font-family: 'Athiti', sans-serif;
}

#login-image-title-2 h2 {
  font-size: 26px;
  text-align: center;
  font-weight: 700;
  color: white;
  font-family: 'Athiti', sans-serif;
  margin-top: 10px;
}
#login-form {
  margin-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#login-form-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#login-form-logo img {
  width: 120px;
  height: 100px;
}
#login-form-email {
  margin-bottom: 30px;
}
.caption-login-form h1 {
  font-size: 34px;
  text-align: justify-all;
  font-weight: 800;
  padding-bottom: 0;
  margin-bottom: 0;
}
.grey-text-login {
  font-size: 14px;
  color: rgb(143, 140, 140);
  padding-top: 0;
  margin-top: 30px !important;
  margin-bottom: -30px !important;
}
div.dx-button-content {
  letter-spacing: 0.1em;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#login-form-btn-submit {
  margin-top: 40px;
  width: 40vw;
  background-color: #07377c;
  padding: 0;
  height: 5vh;
  padding-top: 3px;
  font-size: 14px;
}

.grey-text-login {
  font-weight: 300;
}

div.dx-texteditor-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
input.dx-texteditor-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#login-form .dx-field-value-static,
.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: 100%;
}

#mobile-screen-layout-img-login {
  width: 0;
  height: 0;
  background: url(/static/media/SlozeniArtikal.85e611d7.JPG);
  background-size: cover;
  margin-bottom: 30px;
}
#login > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#login {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#i_s {
  position: absolute;
  bottom: 2px;
  z-index: 1000;
  left: 50.2vw;
  margin-left: 50px;
}

@media only screen and (max-width: 1400px) {
  .caption-login-form h1 {
    font-size: 32px;
  }
  #login-image-absolute {
    height: 100%;
  }
  #login-image-title-2 {
    top: 35%;
  }
  #login-image-title-2 h1 {
    font-size: 36px;
  }
  #login-image-title-2 h2 {
    font-size: 30px;
  }
  #login .dx-field-value {
    margin-top: 2vh;
  }
}

@media only screen and (max-width: 1250px) {
  .caption-login-form h1 {
    font-size: 28px;
  }
  #login-image-title-2 h1 {
    font-size: 32px;
  }
  #login-image-title-2 h2 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 1100px) {
  .caption-login-form h1 {
    font-size: 24px;
  }
  #login-image-title-2 h1 {
    font-size: 28px;
  }
  #login-image-title-2 h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 950px) {
  .caption-login-form h1 {
    font-size: 22px;
  }
  #login-image-title-2 h1 {
    font-size: 22px;
  }
  #login-image-title-2 h2 {
    font-size: 16px;
  }
  #login-image,
  #login-image-absolute {
    width: 0;
    height: 0;
  }
  #login-image-title-2 {
    width: 0;
    height: 0;
  }
  #login-image-title-2 h2,
  #login-image-title-2 h1 {
    font-size: 0;
  }
  #mobile-screen-layout-img-login {
    width: 100%;
    height: 20vh;
    margin-top: 15px;
  }
  #login > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  #login-form {
    margin-left: 20px;
    padding-right: 20px;
    width: 80vw !important;
  }
  #i_s {
    left: 5px !important;
    margin-left: 11vw;
  }
  #login .dx-field-value {
    margin-top: 0;
  }
  .CookieConsent {
    height: 10vh !important;
  }
}

.dark-mode #i_s i,
.dark-mode #login a,
.dark-mode #login i {
  color: rgb(82, 86, 95) !important;
}
.light-mode #i_s i,
.light-mode #login a,
.dark-mode #login i {
  color: rgb(170, 173, 180) !important;
}
@media only screen and (max-width: 850px) {
  .caption-login-form h1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 700px) {
  .caption-login-form h1 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 631px) {
  .caption-login-form h1 {
    font-size: 14px;
  }
  .caption-login-form h2 {
    font-size: 12px;
  }
}
@media only screen and (max-width: 551px) {
  #login i {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 324px) {
  .caption-login-form h1 {
    font-size: 12px;
  }
  .caption-login-form h2 {
    font-size: 10px;
  }
}
@media only screen and (max-width: 1530px) {
  #login-form {
    margin-top: 20vh;
  }
  #login form {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 1100px) {
  #login-form {
    margin-top: 33vh;
  }
}
/*
@media only screen and (max-width: 563px) {
  input {
    font-size: 12px;
  }
  .caption-login-form h1 {
    font-size: 12px;
  }
  .caption-login-form h2 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 467px) {
  input {
    font-size: 10px;
  }
  .caption-login-form h1 {
    font-size: 10px;
  }
  .caption-login-form h2 {
    font-size: 8px;
  }
}

@media only screen and (max-width: 467px) {
  input {
    font-size: 8px;
  }
  .caption-login-form h1 {
    font-size: 8px;
  }
  .caption-login-form h2 {
    font-size: 6px;
  }
}
*/
#login {
  height: 100% !important;
}
@media only screen and (max-height: 800px) {
  #login {
    height: 760px !important;
  }
  #login h3 {
    margin: 0 !important;
  }
}

.card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 45px 7px;
  background-color: white;
}
#upit .card {
  width: 70vw !important;
}
#k_p_h_u {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  -webkit-backdrop-filter: blur(12.5px);
          backdrop-filter: blur(12.5px);
}
#korisnik_panel_header_u {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#k_p_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#upit #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  margin-right: 100px !important;
}
#korisnik_panel_header_u img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_u p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
#upit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -99;
}

#upit div.dx-button {
  margin-top: 8px;
  margin-left: 8px;
  background-color: #07377c;
  color: white;
}
#upit #add_btn div.dx-button {
  margin-top: 50px;
}
#upit #add_btn div.dx-button-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#upit div.dx-button {
  margin-top: -1px;
}
#upit .n_b div.dx-texteditor-input-container {
  width: 10vw !important;
}

#upit #r_s_bk div.dx-texteditor-input-container,
#upit #add_btn .dx-button {
  width: 10vw;
}

#upit .cijena input.dx-texteditor-input,
#upit .cijena div.dx-texteditor-input-container {
  text-align: right !important;
  /* width: 15vw !important;*/
}
#upit_title h1 {
  font-size: 20px;
  margin-left: 2vw;
  margin-bottom: -2px;
  width: 100vw !important;
}

#upit input.dx-texteditor-input {
  font-size: 12px;
}
#upit div.dx-item-content .dx-box-item-content {
  padding-top: 0;
}
#upit div.dx-fieldset {
  margin: 0 10px !important;
}

#upit input * {
  height: 2vh;
}

#upit h1,
.cijena-component h1 {
  margin-left: 3vw;
  font-size: 20px;
  margin-bottom: -2px;
}

/**/

.upit-component {
  margin: auto 20vw;
  max-width: 60vw;
}

#upit .dx-field {
  display: flex !important;
  width: 25vw !important;
}

#upit .dx-field-label {
  font-size: 14px !important;
  width: 15vw !important;
  text-align: justify !important;
}

#upit .label_p {
  font-size: 14px !important;
}
#upit .dx-field-value {
  width: 11vw !important;
}

#upit .dx-box-item {
  height: 2vh !important;
}
#upit .upload_crteza {
  margin-top: -40px;
}

#upit .slijepe_rupe {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin-top: -0.9vh !important;
}

#upit .sl_ar {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
#upit .bk_label {
  width: 0 !important;
}
#upit .card_bk * {
  font-size: 14px;
}
#upit #napomena {
  margin-top: 3px !important;
  width: 100% !important;
}

#add_btn {
  height: 5vh !important;
  margin-top: 5px !important;
}
#add_btn .dx-button {
  margin-top: 5px !important;
  margin-left: 0 !important;
  text-align: center;
}
#upit #add_btn {
  display: flex;
  flex-direction: column;
  width: 10.5vw;
}
#upit .slijepe_rupe {
  font-size: 13px;
  white-space: nowrap;
  color: #333;
  font-weight: 600;
  line-height: 1.35715;
}
#upit .r_p_btn .dx-button {
  margin-left: 5px;
  margin-top: 1px;
  width: 120px;
}
#upit .r_p_btn * {
  font-size: 10px;
  padding: none;
}
/**/
.upit-component {
  margin: auto 12.5vw;
  max-width: 70vw;
}

#upit .dx-field {
  display: flex !important;
  width: 33.5vw !important;
}

#upit .dx-field-label {
  font-size: 14px;
  width: 25vw !important;
  text-align: justify !important;
}
#upit .geom-tijelo .dx-field-label {
  width: 25vw !important;
}
#upit .label_p {
  font-size: 14px;
}
#upit .dx-field-value {
  width: 14vw !important;
}

#upit .dx-box-item {
  height: 2vh !important;
}
#upit .upload_crteza {
  margin-top: -40px;
}

#upit .h1 {
  margin-top: 15px;
}
#upit .slijepe_rupe {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

#upit .sl_ar {
  width: 100% !important;
  font-size: 13px;
}
#upit .bk_label {
  width: 0 !important;
}
#upit .card_bk * {
  font-size: 14px;
}
#upit #napomena {
  margin-top: 3px !important;
  width: 100% !important;
}

#add_btn {
  height: 5vh !important;
  margin-top: 5px !important;
}
#add_btn .dx-button {
  margin-top: 5px !important;
}

#upit .r_c_z_k h1,
#upit .p_o_p_z h1 {
  margin-top: -5px !important;
}
#upit .slijepe_rupe {
  font-size: 13px;
  white-space: nowrap;
  color: #333;
  font-weight: 600;
  line-height: 1.35715;
}
#upit .d_n_k {
  margin-left: 10px;
}
#upit .card_bk .dx-button-content * {
  font-size: 10px;
  height: auto;
}
#upit h1,
#upit .h1 {
  margin-bottom: -1px !important;
}

.sl_ar {
  display: flex;
  text-align: center;
}
.sl_ar > div {
  display: flex;
  flex-direction: column;
}
#upit .sl_ar {
  text-align: left;
}
#upit .sl_ar {
  justify-content: space-between !important;
}
#upit div.dx-button {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
#upit .card_bk span.dx-button-text,
#upit i.dx-icon {
  padding-top: 3px;
}
#upit span.dx-checkbox-text {
  padding-top: 3px;
}
#upit .n_a .dx-field-value > div {
  display: flex !important;
  width: 37vw !important;
  margin-left: 1.9vw !important;
}
#upit .s_a {
  width: 27vw !important;
}
#upit .s_a div > div {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#upit .s_a .dx-field {
  width: 28vw !important;
}
#upit .s_a .dx-field .dx-button {
  margin-left: 10px;
}
#upit .r_c_z_k {
  margin-left: 0 !important;
}
#upit #u_i_b {
  display: flex;
  justify-content: start;
}
#upit #u_i_b .n_b {
  width: 10.5vw !important;
  padding: 0;
}
#upit #u_b p {
  width: 1px;
}
#upit #u_b {
  margin: 0 1vw;
  width: 2vw;
}
#upit #u_b > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#upit .mr10 {
  margin: 0 !important;
}
.blur {
  background-color: rgba(5, 5, 5, 0.4);
}
#upit .kalk_pov .dx-button-has-text .dx-button-content {
  padding: 7px 0 8px !important;
}
#upload .dx-button {
  margin-left: 5px;
  width: 120px;
  margin-top: 1px;
  background-color: #07377c;
  color: white;
  font-size: 10px !important;
  padding-top: 1px;
}
#upload .dx-button * {
  font-size: 10px !important;
}
#upload .dx-button {
  outline: none;
  border: none;
}
#upit #left_bk {
  display: flex !important;
  flex-direction: row !important;
}

#upit .i_b_bk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px !important;
}

#upit #r_s_bk {
  display: flex !important;
  flex-direction: row !important;
}
#upit #r_s_bk p {
  margin-bottom: 1px !important;
}
#upit #r_s_bk .dx-field-label {
  display: flex !important;
  justify-content: space-between;
  padding-bottom: 50px !important;
}
#upit #r_s_bk .dx-field-value {
  margin-top: 8px !important;
}
#upit #r_s_bk {
  padding-bottom: 50px !important;
}
#bk {
  margin-bottom: 12px !important;
}
#upit #bk_n_kol {
  margin-top: 30px !important;
}
#upit #bk_n_kol .dx-fieldset {
  margin-left: 0 !important;
}
#upit .l_s_bk .mr10 {
  margin-left: 5px !important;
}
#upit .l_s_bk {
  margin-top: 8px !important;
}
#upit #naruci {
  margin-top: 1px !important;
}
#upit #s_a_btn {
  z-index: 1;
}
#upit #upload .dx-button-has-text .dx-button-content,
#upit #add_btn .dx-button-has-text .dx-button-content {
  text-align: center !important;
  padding: 7px 0 8px !important;
}
#upit #bk {
  padding: 0 !important;
}

#upit .r_c_z_k .dx-field {
  margin-top: 0 !important;
}

@media only screen and (max-width: 1600px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 9vw !important;
  }
  #upit .cijena input.dx-texteditor-input {
    width: 10vw !important;
  }
}

@media only screen and (max-width: 1500px) {
  #upit #korisnik_panel_header_u {
    margin: auto 2vw !important;
  }
  #upit #korisnik_panel_header_u #logout_btn {
    right: 15px !important;
    padding: 0 !important;
    width: auto !important;
  }
  #upit #korisnik_panel_header_u #logout_btn img {
    margin: auto 5px;
  }
  .ghi {
    margin-left: 5px !important;
    right: 4vw;
  }
  .upit-component {
    margin: auto 0;
    max-width: 80vw;
  }
  #upit .dx-field-label {
    width: 15vw !important;
    font-size: 12px !important;
  }
  #upit .dx-field-value {
    min-width: 25vw !important;
  }
  #upit .dx-field {
    min-width: 45vw !important;
  }
  .opis {
    margin-left: 5vw;
  }
  .nk_opis {
    margin-left: 5vw !important;
  }
  #upit .card {
    width: 90vw !important;
  }
  #upit .n_a .dx-field-value > div {
    margin-left: 0 !important;
  }
  #upit .cijena input.dx-texteditor-input {
    width: 24vw !important;
  }
}
@media only screen and (max-width: 1327px) {
  #boja_opis * {
    font-size: 8px;
  }
}
@media only screen and (max-width: 1092px) {
  #upit {
    height: 3350px !important;
    width: 100vw !important;
  }
  #upit .card {
    margin-left: 5vw !important;
  }
  .ghi * {
    margin-left: 0 !important;
  }
  #upit #q_div {
    right: 10px !important;
  }
  #upit .dx-box-flex {
    flex-direction: column !important;

    margin-bottom: 70px !important;
    height: 30vh;
  }
  #upit .dx-box-item {
    height: 15vh !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    height: 60px !important;
  }
  #upit .dx-item,
  #upit .dx-box-item {
    margin-top: 40px !important;
    margin-bottom: -20px !important;
  }
  #upit #upload {
    margin-left: 5px !important;
  }
  #upit .dx-field {
    width: 80vw !important;
    flex-direction: column;
    margin-bottom: 5px;
  }
  #upit .dx-field-label {
    width: 80vw;
  }
  #upit .card_p {
    height: 22vh !important;
  }
  #upit .dx-field-value,
  #upit .n_a .dx-field-value > div {
    width: 80vw !important;
  }
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 10vw;
  }
  #upit .r_p_btn .dx-button {
    margin-left: 10px !important;
  }
  #upit .materijal {
    height: 30vh !important;
  }
  #upit .materijal_opis {
    margin-top: 12.5vh !important;
  }
  #upit .materijal_opis .opis {
    width: 80vw !important;
    background-color: white;

    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
    color: black !important;
  }

  #upit .dimenzioni_kriterij {
    height: 30vh !important;
  }
  #upit .dimenzioni_kriterij_opis .opis {
    width: 80vw !important;
    background-color: white;
    margin-top: 9vh !important;

    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
  }
  #upit .dimenzioni_kriterij_opis .opis * {
    color: black !important;
  }
  #upit .nacin_kacenja {
    height: 70vh !important;
  }
  #upit .nacin_kacenja_opis {
    margin-top: 14.8vh;
  }
  #upit .nacin_kacenja_opis .opis {
    width: 80vw !important;
    background-color: white;
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
  }
  #upit .nacin_kacenja_opis .opis li {
    margin-top: 5px;
  }
  #upit .nacin_kacenja_opis .opis * {
    color: black !important;
  }
  #upit .nacin_povr_zast {
    height: 40vh !important;
  }
  #upit .nacin_povr_zast_opis {
    margin-top: 3.4vh;
  }
  #upit .nacin_povr_zast_opis .opis {
    width: 80vw !important;
    margin-top: 0 !important;
    background-color: white !important;
  }
  #upit .nacin_povr_zast_opis .npz_opis {
    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
  }
  #upit .nacin_povr_zast_opis .opis * {
    color: black !important;
  }
  #upit .nacin_povr_obr {
    height: 50vh !important;
  }
  #upit .nacin_povr_obr_opis .opis {
    width: 80vw !important;
    margin-top: -1.6vh !important;
    background-color: white !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
    color: black !important;
  }

  #upit .nacin_povr_obr_opis .opis * {
    color: black !important;
  }
  #upit .debljina_oks_sloja {
    height: 25vh !important;
  }
  #upit .debljina_oks_sloja_opis {
    margin-top: 0vh;
  }
  #upit .debljina_oks_sloja_opis .list-opis-hppe {
    width: 80vw !important;
    margin-top: 15.2vh !important;
    background-color: white !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 10px;
    border-radius: 5px;
    color: black !important;
  }

  #upit .debljina_oks_sloja_opis .list-opis-hppe * {
    color: black !important;
  }
  .geom-tijelo {
    width: 18vw !important;
  }
  .g_tijela {
    width: 100% !important;
  }
  #modal-left-container {
    width: 50vw !important;
  }
  #modal-left-container-inputs,
  #float-buttons {
    margin-left: 50px;
  }
  .popup-item {
    margin-left: 50px;
  }
  #tabela-povrsina-2 .dx-datagrid {
    width: 30vw !important;
  }
  #upit * {
    font-size: 12px;
  }
  #upit input,
  #upit div.dx-item-content .dx-list-item-content {
    font-size: 10px;
  }

  #npz_opis *,
  #boja_opis * {
    font-size: 8px;
  }
  #npz_opis,
  #boja_opis {
    background-color: white;
  }
  #upit .povrsina .dx-field-label .naziv-geom-tijela {
    width: 10vw !important;
  }

  #upit #u_i_b,
  #upit #u_b > div,
  #upit #left_bk,
  #upit #r_s_bk {
    flex-direction: column !important;
  }

  #upit #r_s_bk .dx-field-label {
    width: 80vw !important;
  }
  #upit #r_s_bk .dx-texteditor-container {
    width: 60vw !important;
  }

  #upit #add_btn .dx-button {
    margin-bottom: 10vh !important;
  }
  #upit #add_btn .dx-button,
  #upit #add_btn .dx-button-has-text .dx-button-content {
    width: 60vw !important;
  }
  #upit h1,
  #upit .h1 {
    width: 100% !important;
  }
  #upit .d_n_k .dx-button {
    margin-top: 160px !important;
  }
  #upit #n .dx-button {
    margin-top: 50px !important;
    margin-left: 0 !important;
    width: 78vw !important;
  }
  #upit .card_bk_rc {
    padding-bottom: 21vh !important;
  }
  #upit #close {
    position: absolute;
    right: 16.2vw !important;
    width: 40px !important;
  }
  #upit #calc {
    width: 37px !important;
    padding: 0 !important;
    font-size: 14px;
  }
  #upit .cijena {
    width: 60vw !important;

    margin-top: -50px !important;
  }
  #upit .l_s_bk .i_b_bk {
    width: 10vw !important;
  }
  #upit #bk {
    height: 30vh;
  }
  #upit .label {
    width: 100% !important;
  }
  #upit .card_sa {
    height: 20vh !important;
  }
}
@media only screen and (max-width: 1040px) {
  #upit #number p {
    font-size: 16px;
  }
  #korisnik_panel_header_u {
    height: 70px;
  }
}

@media only screen and (max-width: 900px) {
  #korisnik_panel_header_u #logout_btn {
    top: 9px !important;
  }
  #korisnik_panel_header_u #logout_btn img {
    height: 15px;
  }
}
@media only screen and (max-width: 785px) {
  .s_a_label * {
    font-size: 10px;
  }
  .slijepe_rupe * {
    font-size: 10px;
  }
}
@media only screen and (max-width: 622px) {
  #upit #close {
    right: 16.5vw !important;
  }
}
@media only screen and (max-width: 587px) {
  #upit #napomena {
    font-size: 10px;
  }
  #SI,
  #ZL,
  #TI,
  #CV,
  #PL,
  #ZE,
  #NA,
  #CR {
    width: 3vw;
  }
}
@media only screen and (max-width: 558px) {
  #upit #number p {
    font-size: 12px;
  }
  #korisnik_panel_header_u {
    height: 65px;
  }
}
@media only screen and (max-width: 489px) {
  #upit * {
    font-size: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .r_p_btn div div .dx-button-content {
    font-size: 10px;
  }
  #upit #napomena {
    font-size: 9px;
  }
  #upit * {
    font-size: 10px;
  }
}

@media only screen and (max-width: 461px) {
  #upit * {
    font-size: 8px;
  }
  #SI,
  #ZL,
  #TI,
  #CV,
  #PL,
  #ZE,
  #NA,
  #CR {
    width: 4vw;
  }
}
@media only screen and (max-width: 445px) {
  #korisnik_panel_header_u #number {
    margin-right: 65px !important;
  }
}
@media only screen and (max-width: 421px) {
  #upit * {
    font-size: 8px;
  }
}
@media only screen and (max-width: 414px) {
  #upit * {
    font-size: 7px;
  }
  #korisnik_panel_header_u #number {
    margin-right: 55px !important;
  }
}
@media only screen and (max-width: 403px) {
  #upit * {
    font-size: 7px;
  }
  #SI,
  #ZL,
  #TI,
  #CV,
  #PL,
  #ZE,
  #NA,
  #CR {
    width: 6vw;
  }
}
#cijena_footer {
  margin: 2px 0 auto;
  max-width: 60vw;
}
@media only screen and (max-width: 398px) {
  #korisnik_panel_header_u #number {
    margin-right: 54px !important;
  }
}
@media only screen and (max-width: 390px) {
  #korisnik_panel_header_u #number {
    margin-right: 55px !important;
  }
  #korisnik_panel_header_u #number p {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 388px) {
  #upit #close {
    right: 17vw !important;
  }
}
@media only screen and (max-width: 350px) {
  #upit #close {
    right: 15vw !important;
  }
}
@media only screen and (max-width: 320px) {
  #upit #close {
    right: 12vw !important;
  }
}
/*
@media only screen and (max-width: 1092px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 180px;
  }
}
@media only screen and (max-width: 1327px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 160px;
  }
}
@media only screen and (max-width: 1247px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 140px;
  }
}
*/

@media only screen and (max-width: 1371px) {
  .upit-component {
    margin: auto 0;
    max-width: 90vw !important;
  }
  .card {
    width: 90vw;
  }

  #upit .r_p_btn {
    right: 40vw;
    width: auto;
  }
}
#upit .bk_input_row {
  width: 50vw !important;
}
#upit .bk_input_row .dx-field-value {
  width: 10vw !important;
}
#upit .dx-field-label .bk_label {
  width: 0 !important;
}

#upit .n_b_c div.dx-texteditor-input-container,
#upit .n_b_c input.dx-texteditor-input {
  text-align: right !important;
}
#upit .u_i_b_c .n_b {
  width: 10vw;
}
#upit .u_i_b_c .dx-button {
  margin-left: 10px !important;
}
#upit #narudzba_div {
  width: 50% !important;
}

#upit #s_a_btn > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}
@media only screen and (max-height: 796px) {
  #upit .card_p,
  #upit .card_sa {
    padding-bottom: 10vh !important;
    height: 30vh !important;
    margin-bottom: 3vh !important;
  }
  #upit .card_p #upit .r_p_btn {
    padding-bottom: 10vh !important;
  }

  #upit .card_bk_rc {
    padding-bottom: 31vh !important;
  }
}
@media only screen and (max-height: 750px) {
  #upit #bk {
    height: 35vh !important;
  }
}
@media only screen and (max-height: 670px) {
  #upit #bk {
    height: 40vh !important;
  }
}
@media only screen and (max-height: 604px) {
  #upit .card_bk_rc {
    padding-bottom: 41vh !important;
  }
  #upit #bk {
    height: 42vh !important;
  }
}
@media only screen and (max-height: 574px) {
  #upit .card_p,
  #upit .card_sa {
    height: 35vh !important;
  }
  #upit .card_bk_rc {
    padding-bottom: 51vh !important;
  }
  #upit #bk {
    height: 50vh !important;
  }
}
@media only screen and (max-height: 492px) {
  #upit .card_p,
  #upit .card_sa {
    height: 37vh !important;
  }
  #upit .card_bk_rc {
    padding-bottom: 61vh !important;
  }
  #upit #bk {
    height: 55vh !important;
  }
}
@media only screen and (max-height: 400px) {
  #upit .card_bk_rc {
    padding-bottom: 71vh !important;
  }
  #upit #bk {
    height: 60vh !important;
  }
}
#upit h1,
#upit .h1 {
  width: 100% !important;
}

@media only screen and (max-width: 1570px) {
  .card_sa > div {
    display: flex;
    flex-direction: column !important;
  }
  .card_sa .dx-button {
    margin-left: 27vw !important;
  }
}

.sgfsgd {
  width: 475px;
  height: 648px;
}

#header,
#footer {
  margin: 0;
  padding: 0;
  width: 475px;
}
#header {
  margin-bottom: 30px;
}
#footer {
  margin-top: 20px;
}
#adresa {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90px;
}
#adresa {
  width: 229px;
}
#adresa-1 {
  height: 82px;
}
#adresa-1 h3 {
  font-size: 13px;
  text-align: left;
  width: 140px;
  margin-top: 0;
  margin-bottom: 0;
}
#adr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 475px;
}

#adresa-3 {
  width: 50%;
  height: 94px;
  border: 1px solid black;
}

#adresa-3-1 {
  border-bottom: 1px solid black;
  display: flex;
}

#adresa-3-1-1 {
  margin-left: 5px;
  width: 50%;
}

#adresa-3-1-1-1 {
  font-size: 14px;
  font-weight: 600;
}
#adresa-3-1-2 {
  text-align: center;
  width: 50%;
  font-size: 13px;
}
#adresa-3-2-1 {
  width: 50%;
}
#adresa-3-2-1 * {
  margin-left: 2px;
  font-size: 12px;
}

#adresa-3-2-1-2,
#adresa-3-2-1-3 {
  font-weight: 400;
}

#adresa-3-2 {
  display: flex;
}

#adresa-3-2-2 {
  width: 50%;
  text-align: center;
  margin-top: 1px;
  padding-bottom: 1px;
  font-size: 14px;
}

#narudzba-1-1 * {
  font-weight: 700;
  font-size: 10px;
  width: 100px;
}
#narudzba-1-2 * {
  font-weight: 700;
  font-size: 10px;
  width: 320px;
}
#narudžba-1 {
  display: flex;
  justify-content: flex-start;
}

#narudzba-1-1 {
  margin-right: 40px;
}

#narudzba p {
  font-weight: 600;
}
#narudzba b {
  font-size: 13px;
  border-bottom: 1px solid black;
}

#pozdrav * {
  font-size: 8px;
  font-weight: 600;
  width: 340px;
}
#pozdrav p {
  margin-bottom: 16px;
}
#tabela-report .dx-datagrid {
  width: 475px;
  font-size: 6px;
}
.sgfsgd {
  margin-left: 5px;
}
#adresa-3-1-2 {
  margin-top: 5px;
}
#adresa-2 {
  display: flex;
  justify-content: space-around;
  width: 115px;
}
.adresa-2-1 {
  display: flex;
  justify-content: space-between;
}
.adresa-2-1 * {
  width: 120px;
  margin-left: 8px;
}
/*
#adresa-1 {
  width: 126px;
  font-weight: 700;
  font-size: 18px;
}

#adresa-2 {
  display: flex;
  justify-content: space-around;
  width: 115px;
}
#adresa {
  width: 25vw;
  height: 100px;
}

.adresa-2-1 * {
  font-weight: 500;
  font-size: 12px;
}
#pdf {
  padding-left: 45px;
  height: 100%;
  width: 100%;
}
#pdf * {
  font-family: Arial;
}

#adr {
  display: flex;
  flex-direction: row;
  width: 229.5px;
}
#tabela-report .dx-datagrid {
  width: 229.5px;
  font-size: 8px;
}
#adresa-3 {
  width: 25vw;
  height: 16vh;
  border: 2px solid black;
}

#adresa-3-1 {
  border-bottom: 2px solid black;
  display: flex;
}

#adresa-3-1-1 {
  margin-left: 5px;
  width: 50%;
}

#adresa-3-1-1-1 {
  font-size: 20px;
  font-weight: 600;
}
#adresa-3-1-2 {
  text-align: center;
  width: 50%;
  font-size: 22px;
}
#adresa-3-2-1 {
  width: 50%;
}
#adresa-3-2-1 * {
  margin-left: 5px;
  font-size: 16px;
}

#adresa-3-2-1-2,
#adresa-3-2-1-3 {
  font-weight: 400;
}

#adresa-3-2 {
  display: flex;
}

#adresa-3-2-2 {
  width: 50%;
  text-align: center;
  margin-top: 2vh;
  padding-bottom: 2vh;
  font-size: 20px;
}

#narudzba-1-1 *,
#narudzba-1-2 *,
#narudzba p {
  font-weight: 700;
  font-size: 12px;
  width: 10vw;
}

#narudžba-1 {
  display: flex;
  justify-content: flex-start;
}

#narudzba-1-1 {
  margin-right: 40px;
}
.dx-datagrid-headers {
  color: black;
  font-weight: bold;
}

.dx-datagrid .dx-row > td {
  font-family: Arial, Helvetica, sans-serif;
}

.dx-datagrid {
  width: 80vw;
}

#narudzba p {
  font-weight: 600;
}
#narudzba b {
  font-size: 18px;
  border-bottom: 1px solid black;
}

#pozdrav * {
  font-size: 12px;
  font-weight: 600;
  width: 229.5px;
}

#adresa h3 {
  margin-top: 0;
}

.whsp {
  margin-left: 5px;
}

#adresa-3-1-1-2 {
  font-size: 16px;
  font-weight: 600;
}

#pdf * {
  font-family: "Arial";
}

#adresa-3-1-2-1 {
  font-size: 18px;
  margin-left: 15px;
  text-align: start;
}


.report-cell-render {
  list-style-type: none;
  padding: 0;
  text-align: start;
}
*/

#upload_draw div.dx-button {
  background-color: #07377c;
  color: white;
  font-size: 10px !important;
}
#upload_draw div.dx-button * {
  font-size: 10px !important;
}
#upload_draw form {
  margin: 0 !important;
  padding: 0 !important;
}
#upload_draw label {
  margin: 0 !important;
}
#upload_draw {
  margin-left: 0;
}
#upload_draw #label .dx-button {
  margin-left: 5px;
  width: 120px;
}
#upload_draw {
  display: flex;
}
.progress {
  width: 120px !important;
  margin: 12px 3px 0 10px !important;
}
.progress_false {
  width: 120px;
}
.progress_true {
  width: 240px;
}
#upload_draw .dx-overlay-content div.dx-button {
  background: transparent !important;
}

#tabela-povrsina-2 .dx-datagrid {
  width: 36vw;
}
#modal {
  margin: 20px;
}
#float-buttons {
  display: flex;
  justify-content: space-around;
}

.g_tijela {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 80%;
}
.geom-tijelo {
  display: flex;
  flex-direction: row;
  width: 10vw;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

  margin: 20px;
  background-color: white;
}
.geom-tijelo > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
#modal_img {
  width: 9vw;
  height: 11vh;
}

@media only screen and (max-width: 991px) {
  #modal-povrsina {
    width: 90%;
  }
  #modal-povrsina * {
    font-size: 10px;
  }
  #modal-povrsina .popup-item {
    margin-left: 10px !important;
  }
}
@media only screen and (max-width: 755px) {
  #modal-povrsina div.dx-datagrid {
    width: 40vw;
  }
}
@media only screen and (max-width: 680px) {
  #modal-povrsina {
    flex-direction: column !important;
    width: 100% !important;
  }
  #modal-povrsina div.dx-datagrid,
  #modal-left-container {
    width: 100% !important;
  }

  #modal-povrsina img {
    width: 30px;
    height: 30px;
  }
  .geom-tijelo {
    margin: 5px;
    padding: 5px;
  }
  #modal-povrsina div.dx-button {
    width: 10%;
    height: 10%;
    justify-content: center;
    padding-left: 3px;
  }
  #modal-povrsina div.dx-button * {
    width: 90%;
    height: 90%;
  }
  #modal_img {
    width: 13vw !important;
    height: 10vh !important;
  }

  #modal-left-container-inputs {
    margin-left: 0;
    width: 100% !important;
  }
  .povrsina label {
    padding-right: 5px;
  }
  .geom-tijelo {
    width: 25vw !important;
    height: 25vh !important;
    text-align: center;
  }
  .geom-tijelo .dx-field-label {
    padding-left: 5vw !important;
    width: 25vw !important;
  }
}

@media only screen and (max-width: 535px) {
  #modal-povrsina div.dx-button {
    width: 13%;
    height: 13%;
  }
}
@media only screen and (max-width: 441px) {
  #upit .geom-tijelo .dx-field-label {
    font-size: 12px !important;
  }
  #modal-povrsina div.dx-button {
    width: 15%;
    height: 15%;
  }
}
@media only screen and (max-width: 388px) {
  .geom-tijelo {
    height: 20vh !important;
  }
  #upit .geom-tijelo .dx-field-label {
    font-size: 10px !important;
    text-align: center !important;
    padding-left: 3vw !important;
  }
  #modal-povrsina div.dx-button {
    width: 30px;
    height: 30px;
  }
  #float-buttons {
    width: 100% !important;
    margin-left: 0;
  }
}
@media only screen and (max-width: 356px) {
}
/*
@media only screen and (max-width: 625px) {
  #modal-povrsina div.dx-datagrid {
    width: 40vw;
  }
}
@media only screen and (max-width: 565px) {
  #modal-povrsina div.dx-datagrid {
    width: 42vw;
  }
  .g_tijela {
    width: 40vw;
  }
  .geom-tijelo {
    width: 12vw;
  }
  .geom-tijelo * {
    padding-right: 5px;
  }
}
@media only screen and (max-width: 471px) {
  #modal-povrsina div.dx-datagrid {
    width: 36vw;
  }
  #modal-povrsina #modal-left-container-inputs,
  #modal-povrsina #float-buttons {
    margin-left: -10px !important;
    width: 40vw !important;
  }
}

@media only screen and (max-width: 447px) {
  #modal-povrsina div.dx-datagrid {
    width: 38vw;
  }
  .geom-tijelo {
    width: 13vw;
  }
}
@media only screen and (max-width: 405px) {
  .geom-tijelo {
    width: 16vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  #modal-povrsina .naziv-geom-tijela {
    font-size: 9px !important;
  }
  #modal_img {
    width: 9vw !important;
    height: 8vh !important;
  }
}


*/

#modal-left-container-inputs,
#float-buttons {
  width: 70%;
}
.geom-tijelo {
  background-color: white !important;
}
.dark-mode .geom-tijelo {
  background-color: black !important;
  z-index: 100 !important;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  -webkit-filter: url(#gaussian-blur);
          filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(15, 14, 14, 0.9);
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: black;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #07377c;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  font-family: 'Athiti', sans-serif;
}
#registerButton {
  background-color: #07377c;
  color: white;
  width: 100%;
}

#register {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
}
#register-image {
  width: 50vw;
}
#register-form {
  width: 40vw;
}
#register-image-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
}

#register form {
  margin-left: 30px;
}
#register div.dx-form-group-with-caption.dx-form-group.dx-group-colcount-1 {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}

#register-form {
  margin-top: 50px;
}
#mobile-screen-layout-img-register {
  width: 0;
  height: 0;
  background: url(/static/media/SlozeniArtikal.85e611d7.JPG);
  background-size: cover;
}

@media only screen and (max-width: 1553px) {
  #register-image-absolute img {
    height: 0vh;
  }
  #register-image {
    width: 0;
  }
  #register-image-absolute {
    width: 0;
  }
  #register form {
    width: 80vw;
  }
  #register {
    justify-content: flex-start;
  }
  #mobile-screen-layout-img-register {
    width: 100%;
    height: 20vh;
  }
  .dark-mode #register-form {
    margin-left: 5vw;
  }
  .dark-mode #register {
    width: 100vw !important;
    height: 220% !important;
  }
  .def {
    right: 15vw !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .def li {
    margin-right: 5px !important;
  }
}
.dark-mode #register {
  height: 180vh;
  width: 99vw;
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 850px) {
  .dark-mode #register {
    width: 100vw !important;
    height: 200% !important;
  }
  #register span,
  #register a {
    font-size: 12px !important;
  }
  #register label {
    width: 45vw !important;
  }
}
.dodatna_pitanja {
  width: 100%;
}

#register_wait {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute !important;
  top: 0 !important;
  width: 100vw !important;
  left: 0 !important;
  height: 100vh !important;
}

#register_wait_images_wrapper {
  width: 55%;
  height: 80%;
}
#register_wait_images_wrapper {
  display: flex;
  justify-content: flex-start;
  padding-right: 0;
  padding-bottom: 0;
}
#register_wait_image_container_1 {
  margin-right: 20px;
  width: 40vw;
}

#register_wait_image_container_2 {
  margin-top: 10vh !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#register_wait_image_container_2 > div {
  display: flex;
  flex-direction: column;
}

#image3 {
  z-index: 1;
  margin-top: 45px;
}

#image1,
#image2,
#image3 {
  height: 90%;
}
#image2 {
  max-width: 100%;
  width: 500px;
  vertical-align: middle;
  margin-top: 40px;
  opacity: 0.8982;
  -webkit-transform: translate(0%, 1.0176%) translate3d(0px, 0px, 0px);
          transform: translate(0%, 1.0176%) translate3d(0px, 0px, 0px);
}
#register_wait_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#register_wait_text_container h1 {
  padding: 40px;
  font-weight: 700;
}
#register_wait_text {
  text-align: justify;
  padding: 40px;
  font-size: 16px;
}

@media only screen and (max-width: 1000px) {
  #register_wait img {
    width: 250px;
  }
  #register_wait_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 770px) {
  #register_wait img {
    width: 200px;
  }
}

@media only screen and (max-width: 645px) {
  #register_wait img {
    width: 150px;
  }
  #register_wait_images_wrapper {
    width: 50%;
  }
}
@media only screen and (max-width: 555px) {
  #register_wait img {
    width: 120px;
  }
  #register_wait h1 {
    margin-bottom: -50px;
  }
}
@media only screen and (max-width: 521px) {
  #register_wait {
    flex-direction: column;
  }
  #register_wait_images_wrapper {
    margin-top: 50px;
  }
  #register_wait_images_wrapper img {
    width: 70vw;
    height: 25vh;
    margin-right: 10vw;
  }
  #register_wait_image_container_1,
  #register_wait #image3 {
    height: 0;
    width: 0;
  }
  #register_wait h1,
  #register_wait_text {
    margin: -20px;
  }
}

#slozeni_artikal {
  max-width: 55vw !important;
  position: absolute;
  left: 0;
}
#slozeni_artikal .card {
  margin-left: 0 !important;
}
#slozeni_artikal .dx-field-label {
  width: 100% !important;
}
#k_p_h_sa {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  -webkit-backdrop-filter: blur(12.5px);
          backdrop-filter: blur(12.5px);
}
#korisnik_panel_header_sa {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#slozeni_artikal #title {
  /* background: url('../Images/SlozeniArtikal.JPG');*/
  background-size: cover;
  width: 100%;
  margin: 0;
  height: 10vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#slozeni_artikal #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  margin-right: 100px !important;
}
#korisnik_panel_header_sa img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_sa p {
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}

#slozeni_artikal #title h2 {
  font-family: 'Athiti', sans-serif;
  color: black;
  font-size: 22px !important;
  /*font-size: 30px !important;*/
  text-align: left;
}
#slozeni_artikal .send-request h2 {
  color: white;
  font-family: 'Athiti', sans-serif;
}
.lista_podataka {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#slozeni_artikal .card {
  padding: 10px;
  width: 55vw !important;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 45px 17px;
  background-color: white;
}
.lista_podataka div {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  grid-row-gap: 1px;
  row-gap: 1px;
  margin-bottom: 5px;
}

#upit_detalji {
  width: 40vw;
}
#upit_detalji h1 {
  margin-top: -15px;
}
#slozeni_artikal h1,
#slozeni_artikal h2 {
  font-size: 26px !important;
}
#slozeni_artikal .dx-field {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
#slozeni_artikal .dx-field-label {
  width: 50vw;
  font-size: 13px;
  margin-top: 5px;
}
#slozeni_artikal .k-button {
  background-color: #07377c;
  font-size: 12px;
  padding: 4px 10px !important;
}
#slozeni_artikal div.dx-button-content {
  background-color: #07377c;
  color: white;
}

#slozeni_artikal .send-request {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#slozeni_artikal .send-request h2 {
  font-family: 'Athiti', sans-serif;
  color: black;
  font-size: 36px;
}

#s_a {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  z-index: -99;
}
#slozeni_artikal div.dx-button {
  margin-top: 10px;
}

#slozeni_artikal .dx-button-has-text .dx-button-content {
  padding: 4px 10px !important;
  font-size: 12px;
}

#k_p_h_sa {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  -webkit-backdrop-filter: blur(12.5px);
          backdrop-filter: blur(12.5px);
}
#korisnik_panel_header_sa {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#k_p_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#slozeni_artikal #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 100px !important;
}
#korisnik_panel_header_sa img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_sa p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
#korisnik_panel_header_sa #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 100px !important;
}

@media only screen and (max-width: 1385px) {
  #slozeni_artikal #title {
    width: 100% !important;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 820px) {
  .s_a_n_u p {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 807px) {
  #slozeni_artikal * {
    font-size: 13px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 38px;
  }
  #korisnik_panel_header_sa #number {
    margin-right: 80px !important;
  }
}
@media only screen and (max-width: 774px) {
  #korisnik_panel_header_sa #number {
    margin-right: 70px !important;
  }
}
@media only screen and (max-width: 739px) {
  #slozeni_artikal #title h2 {
    width: 100% !important;
    font-size: 25px !important;
  }
  #slozeni_artikal .card {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 677px) {
  #slozeni_artikal * {
    font-size: 12px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 627px) {
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 28px;
  }
  #slozeni_artikal #title h2 {
    font-size: 18px !important;
  }
  #slozeni_artikal .dx-field-label {
    width: 100vw !important;
  }
}
@media only screen and (max-width: 531px) {
  #slozeni_artikal * {
    font-size: 11px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 25px;
  }
  #slozeni_artikal {
    max-width: 90vw;
    margin: auto 5vw;
  }
}
@media only screen and (max-width: 477px) {
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 437px) {
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 381px) {
  #slozeni_artikal * {
    font-size: 10px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 313px) {
  #slozeni_artikal * {
    font-size: 9px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 263px) {
  #slozeni_artikal * {
    font-size: 8px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 11px;
  }
}

#slozeni_artikal {
  margin: auto 20vw;

  width: 55vw !important;
}
#upit_detalji {
  width: 100%;
}
#slozeni_artikal .card {
  width: 100% !important;
}
#slozeni_artikal .card .dx-field {
  width: 100% !important;
  justify-content: space-between;
}

#korisnik_panel_header_sa img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_sa p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 1500px) {
  #korisnik_panel_header_sa #logout_btn {
    right: 2vw !important;
    padding: 0 !important;
    width: auto !important;
  }
  #slozeni_artikal {
    margin: auto 2vw;
  }
  #korisnik_panel_header_sa #logout_btn img {
    margin: auto 5px;
  }
  #korisnik_panel_header_sa {
    margin: auto 2vw;
  }
  #korisnik_panel_header_sa #number {
    margin-right: 50px;
  }
  #slozeni_artikal .card {
    width: 90vw !important;
  }
  .mno {
    margin-left: 5px !important;
    right: 4vw;
  }
}
@media only screen and (max-width: 1160px) {
  #slozeni_artikal {
    width: 100vw !important;
    max-width: 98vw !important;
  }
}
@media only screen and (max-width: 1092px) {
  .mno * {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 1040px) {
  #slozeni_artikal #number p {
    font-size: 16px;
  }
  #korisnik_panel_header_sa {
    height: 70px;
  }
}

@media only screen and (max-width: 900px) {
  #korisnik_panel_header_sa #logout_btn {
    top: 9px !important;
  }
  #korisnik_panel_header_sa #logout_btn img {
    height: 15px;
  }
}

@media only screen and (max-width: 558px) {
  #korisnik_panel_header_sa #number p {
    font-size: 12px !important;
  }
  #korisnik_panel_header_sa {
    height: 65px;
  }
}
@media only screen and (max-width: 414px) {
  #korisnik_panel_header_sa #number {
    margin-right: 55px !important;
  }
}

@media only screen and (max-width: 398px) {
  #korisnik_panel_header_sa #number {
    margin-right: 54px !important;
  }
}
@media only screen and (max-width: 390px) {
  #korisnik_panel_header_sa #number {
    margin-right: 55px !important;
  }
  #korisnik_panel_header_sa #number p {
    font-size: 10px !important;
  }
}

