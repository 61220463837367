@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@500;600;700&display=swap');
#slozeni_artikal {
  max-width: 55vw !important;
  position: absolute;
  left: 0;
}
#slozeni_artikal .card {
  margin-left: 0 !important;
}
#slozeni_artikal .dx-field-label {
  width: 100% !important;
}
#k_p_h_sa {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  backdrop-filter: blur(12.5px);
}
#korisnik_panel_header_sa {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#slozeni_artikal #title {
  /* background: url('../Images/SlozeniArtikal.JPG');*/
  background-size: cover;
  width: 100%;
  margin: 0;
  height: 10vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#slozeni_artikal #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  margin-right: 100px !important;
}
#korisnik_panel_header_sa img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_sa p {
  color: white;
  font-size: 16px;
  font-weight: 400;
  text-align: right;
}

#slozeni_artikal #title h2 {
  font-family: 'Athiti', sans-serif;
  color: black;
  font-size: 22px !important;
  /*font-size: 30px !important;*/
  text-align: left;
}
#slozeni_artikal .send-request h2 {
  color: white;
  font-family: 'Athiti', sans-serif;
}
.lista_podataka {
  display: flex;
  flex-direction: column;
  width: 100%;
}
#slozeni_artikal .card {
  padding: 10px;
  width: 55vw !important;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 45px 17px;
  background-color: white;
}
.lista_podataka div {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  row-gap: 1px;
  margin-bottom: 5px;
}

#upit_detalji {
  width: 40vw;
}
#upit_detalji h1 {
  margin-top: -15px;
}
#slozeni_artikal h1,
#slozeni_artikal h2 {
  font-size: 26px !important;
}
#slozeni_artikal .dx-field {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
#slozeni_artikal .dx-field-label {
  width: 50vw;
  font-size: 13px;
  margin-top: 5px;
}
#slozeni_artikal .k-button {
  background-color: #07377c;
  font-size: 12px;
  padding: 4px 10px !important;
}
#slozeni_artikal div.dx-button-content {
  background-color: #07377c;
  color: white;
}

#slozeni_artikal .send-request {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#slozeni_artikal .send-request h2 {
  font-family: 'Athiti', sans-serif;
  color: black;
  font-size: 36px;
}

#s_a {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  z-index: -99;
}
#slozeni_artikal div.dx-button {
  margin-top: 10px;
}

#slozeni_artikal .dx-button-has-text .dx-button-content {
  padding: 4px 10px !important;
  font-size: 12px;
}

#k_p_h_sa {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  backdrop-filter: blur(12.5px);
}
#korisnik_panel_header_sa {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#k_p_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#slozeni_artikal #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 100px !important;
}
#korisnik_panel_header_sa img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_sa p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
#korisnik_panel_header_sa #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 100px !important;
}

@media only screen and (max-width: 1385px) {
  #slozeni_artikal #title {
    width: 100% !important;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 820px) {
  .s_a_n_u p {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 807px) {
  #slozeni_artikal * {
    font-size: 13px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 38px;
  }
  #korisnik_panel_header_sa #number {
    margin-right: 80px !important;
  }
}
@media only screen and (max-width: 774px) {
  #korisnik_panel_header_sa #number {
    margin-right: 70px !important;
  }
}
@media only screen and (max-width: 739px) {
  #slozeni_artikal #title h2 {
    width: 100% !important;
    font-size: 25px !important;
  }
  #slozeni_artikal .card {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 677px) {
  #slozeni_artikal * {
    font-size: 12px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 627px) {
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 28px;
  }
  #slozeni_artikal #title h2 {
    font-size: 18px !important;
  }
  #slozeni_artikal .dx-field-label {
    width: 100vw !important;
  }
}
@media only screen and (max-width: 531px) {
  #slozeni_artikal * {
    font-size: 11px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 25px;
  }
  #slozeni_artikal {
    max-width: 90vw;
    margin: auto 5vw;
  }
}
@media only screen and (max-width: 477px) {
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 437px) {
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 381px) {
  #slozeni_artikal * {
    font-size: 10px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 313px) {
  #slozeni_artikal * {
    font-size: 9px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 13px;
  }
}
@media only screen and (max-width: 263px) {
  #slozeni_artikal * {
    font-size: 8px;
  }
  #slozeni_artikal #title h2,
  #slozeni_artikal .send-request h2 {
    font-size: 11px;
  }
}

#slozeni_artikal {
  margin: auto 20vw;

  width: 55vw !important;
}
#upit_detalji {
  width: 100%;
}
#slozeni_artikal .card {
  width: 100% !important;
}
#slozeni_artikal .card .dx-field {
  width: 100% !important;
  justify-content: space-between;
}

#korisnik_panel_header_sa img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_sa p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 1500px) {
  #korisnik_panel_header_sa #logout_btn {
    right: 2vw !important;
    padding: 0 !important;
    width: auto !important;
  }
  #slozeni_artikal {
    margin: auto 2vw;
  }
  #korisnik_panel_header_sa #logout_btn img {
    margin: auto 5px;
  }
  #korisnik_panel_header_sa {
    margin: auto 2vw;
  }
  #korisnik_panel_header_sa #number {
    margin-right: 50px;
  }
  #slozeni_artikal .card {
    width: 90vw !important;
  }
  .mno {
    margin-left: 5px !important;
    right: 4vw;
  }
}
@media only screen and (max-width: 1160px) {
  #slozeni_artikal {
    width: 100vw !important;
    max-width: 98vw !important;
  }
}
@media only screen and (max-width: 1092px) {
  .mno * {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 1040px) {
  #slozeni_artikal #number p {
    font-size: 16px;
  }
  #korisnik_panel_header_sa {
    height: 70px;
  }
}

@media only screen and (max-width: 900px) {
  #korisnik_panel_header_sa #logout_btn {
    top: 9px !important;
  }
  #korisnik_panel_header_sa #logout_btn img {
    height: 15px;
  }
}

@media only screen and (max-width: 558px) {
  #korisnik_panel_header_sa #number p {
    font-size: 12px !important;
  }
  #korisnik_panel_header_sa {
    height: 65px;
  }
}
@media only screen and (max-width: 414px) {
  #korisnik_panel_header_sa #number {
    margin-right: 55px !important;
  }
}

@media only screen and (max-width: 398px) {
  #korisnik_panel_header_sa #number {
    margin-right: 54px !important;
  }
}
@media only screen and (max-width: 390px) {
  #korisnik_panel_header_sa #number {
    margin-right: 55px !important;
  }
  #korisnik_panel_header_sa #number p {
    font-size: 10px !important;
  }
}
