#draw {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

#draw > div {
  display: flex;
  justify-content: center;
}

#draw div.dx-button {
  margin-top: 8px;
  margin-left: 8px;
  background-color: black;
  color: white;
}
.dark-mode #draw {
  background-color: #464444;
}

.dark-mode #draw * {
  color: white;
}
.dark-mode #draw input {
  border-bottom: 1px solid white;
}
