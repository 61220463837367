#upload_draw div.dx-button {
  background-color: #07377c;
  color: white;
  font-size: 10px !important;
}
#upload_draw div.dx-button * {
  font-size: 10px !important;
}
#upload_draw form {
  margin: 0 !important;
  padding: 0 !important;
}
#upload_draw label {
  margin: 0 !important;
}
#upload_draw {
  margin-left: 0;
}
#upload_draw #label .dx-button {
  margin-left: 5px;
  width: 120px;
}
#upload_draw {
  display: flex;
}
.progress {
  width: 120px !important;
  margin: 12px 3px 0 10px !important;
}
.progress_false {
  width: 120px;
}
.progress_true {
  width: 240px;
}
#upload_draw .dx-overlay-content div.dx-button {
  background: transparent !important;
}
