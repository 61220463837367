#liste .dx-datagrid {
  max-width: 60vw;
}

#liste div.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row td {
  background-color: #337ab7;
  color: white;
}

#gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

.dark #gridContainer tr.notes-row td {
  color: #777;
}

#liste #gridContainer tr.notes-row td {
  height: 70px;
  color: #999;
}

#liste .dark #gridContainer tr.notes-row td {
  color: #777;
}

#liste #gridContainer tbody.employee:hover {
  background-color: #ebebeb;
}

#liste .dark #gridContainer tbody.employee:hover {
  background-color: #484848;
}

#liste #gridContainer tbody.employee:hover tr.main-row td {
  color: #000;
}

#liste .dark #gridContainer tbody.employee:hover tr.main-row td {
  color: #ccc;
}

#liste #gridContainer tbody.employee:hover tr.notes-row td {
  color: #888;
}

#liste .dx-toolbar .dx-toolbar-items-container {
  height: 30px;
}

.dark-mode #liste .dx-datagrid .dx-row-alt > td,
.dark-mode #liste .dx-datagrid .dx-row-alt > tr > td {
  background-color: rgb(85, 85, 88);
  border-color: rgb(85, 85, 88);
}
