.card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 45px 7px;
  background-color: white;
}
#upit .card {
  width: 70vw !important;
}
#k_p_h_u {
  position: fixed;
  width: 100vw;
  height: 70px;
  z-index: 10;
  background-color: #07377c;
  backdrop-filter: blur(12.5px);
}
#korisnik_panel_header_u {
  display: flex;
  height: 75px;
  flex-direction: row;
  justify-content: space-between;
  margin: auto 20vw;
}

#k_p_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#upit #number {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
  margin-right: 100px !important;
}
#korisnik_panel_header_u img {
  margin: auto 30px;
  margin-left: 45px;
}
#korisnik_panel_header_u p {
  color: white;
  font-size: 16px;
  font-weight: 400;
}
#upit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -99;
}

#upit div.dx-button {
  margin-top: 8px;
  margin-left: 8px;
  background-color: #07377c;
  color: white;
}
#upit #add_btn div.dx-button {
  margin-top: 50px;
}
#upit #add_btn div.dx-button-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#upit div.dx-button {
  margin-top: -1px;
}
#upit .n_b div.dx-texteditor-input-container {
  width: 10vw !important;
}

#upit #r_s_bk div.dx-texteditor-input-container,
#upit #add_btn .dx-button {
  width: 10vw;
}

#upit .cijena input.dx-texteditor-input,
#upit .cijena div.dx-texteditor-input-container {
  text-align: right !important;
  /* width: 15vw !important;*/
}
#upit_title h1 {
  font-size: 20px;
  margin-left: 2vw;
  margin-bottom: -2px;
  width: 100vw !important;
}

#upit input.dx-texteditor-input {
  font-size: 12px;
}
#upit div.dx-item-content .dx-box-item-content {
  padding-top: 0;
}
#upit div.dx-fieldset {
  margin: 0 10px !important;
}

#upit input * {
  height: 2vh;
}

#upit h1,
.cijena-component h1 {
  margin-left: 3vw;
  font-size: 20px;
  margin-bottom: -2px;
}

/**/

.upit-component {
  margin: auto 20vw;
  max-width: 60vw;
}

#upit .dx-field {
  display: flex !important;
  width: 25vw !important;
}

#upit .dx-field-label {
  font-size: 14px !important;
  width: 15vw !important;
  text-align: justify !important;
}

#upit .label_p {
  font-size: 14px !important;
}
#upit .dx-field-value {
  width: 11vw !important;
}

#upit .dx-box-item {
  height: 2vh !important;
}
#upit .upload_crteza {
  margin-top: -40px;
}

#upit .slijepe_rupe {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  margin-top: -0.9vh !important;
}

#upit .sl_ar {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
#upit .bk_label {
  width: 0 !important;
}
#upit .card_bk * {
  font-size: 14px;
}
#upit #napomena {
  margin-top: 3px !important;
  width: 100% !important;
}

#add_btn {
  height: 5vh !important;
  margin-top: 5px !important;
}
#add_btn .dx-button {
  margin-top: 5px !important;
  margin-left: 0 !important;
  text-align: center;
}
#upit #add_btn {
  display: flex;
  flex-direction: column;
  width: 10.5vw;
}
#upit .slijepe_rupe {
  font-size: 13px;
  white-space: nowrap;
  color: #333;
  font-weight: 600;
  line-height: 1.35715;
}
#upit .r_p_btn .dx-button {
  margin-left: 5px;
  margin-top: 1px;
  width: 120px;
}
#upit .r_p_btn * {
  font-size: 10px;
  padding: none;
}
/**/
.upit-component {
  margin: auto 12.5vw;
  max-width: 70vw;
}

#upit .dx-field {
  display: flex !important;
  width: 33.5vw !important;
}

#upit .dx-field-label {
  font-size: 14px;
  width: 25vw !important;
  text-align: justify !important;
}
#upit .geom-tijelo .dx-field-label {
  width: 25vw !important;
}
#upit .label_p {
  font-size: 14px;
}
#upit .dx-field-value {
  width: 14vw !important;
}

#upit .dx-box-item {
  height: 2vh !important;
}
#upit .upload_crteza {
  margin-top: -40px;
}

#upit .h1 {
  margin-top: 15px;
}
#upit .slijepe_rupe {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

#upit .sl_ar {
  width: 100% !important;
  font-size: 13px;
}
#upit .bk_label {
  width: 0 !important;
}
#upit .card_bk * {
  font-size: 14px;
}
#upit #napomena {
  margin-top: 3px !important;
  width: 100% !important;
}

#add_btn {
  height: 5vh !important;
  margin-top: 5px !important;
}
#add_btn .dx-button {
  margin-top: 5px !important;
}

#upit .r_c_z_k h1,
#upit .p_o_p_z h1 {
  margin-top: -5px !important;
}
#upit .slijepe_rupe {
  font-size: 13px;
  white-space: nowrap;
  color: #333;
  font-weight: 600;
  line-height: 1.35715;
}
#upit .d_n_k {
  margin-left: 10px;
}
#upit .card_bk .dx-button-content * {
  font-size: 10px;
  height: auto;
}
#upit h1,
#upit .h1 {
  margin-bottom: -1px !important;
}

.sl_ar {
  display: flex;
  text-align: center;
}
.sl_ar > div {
  display: flex;
  flex-direction: column;
}
#upit .sl_ar {
  text-align: left;
}
#upit .sl_ar {
  justify-content: space-between !important;
}
#upit div.dx-button {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
#upit .card_bk span.dx-button-text,
#upit i.dx-icon {
  padding-top: 3px;
}
#upit span.dx-checkbox-text {
  padding-top: 3px;
}
#upit .n_a .dx-field-value > div {
  display: flex !important;
  width: 37vw !important;
  margin-left: 1.9vw !important;
}
#upit .s_a {
  width: 27vw !important;
}
#upit .s_a div > div {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#upit .s_a .dx-field {
  width: 28vw !important;
}
#upit .s_a .dx-field .dx-button {
  margin-left: 10px;
}
#upit .r_c_z_k {
  margin-left: 0 !important;
}
#upit #u_i_b {
  display: flex;
  justify-content: start;
}
#upit #u_i_b .n_b {
  width: 10.5vw !important;
  padding: 0;
}
#upit #u_b p {
  width: 1px;
}
#upit #u_b {
  margin: 0 1vw;
  width: 2vw;
}
#upit #u_b > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#upit .mr10 {
  margin: 0 !important;
}
.blur {
  background-color: rgba(5, 5, 5, 0.4);
}
#upit .kalk_pov .dx-button-has-text .dx-button-content {
  padding: 7px 0 8px !important;
}
#upload .dx-button {
  margin-left: 5px;
  width: 120px;
  margin-top: 1px;
  background-color: #07377c;
  color: white;
  font-size: 10px !important;
  padding-top: 1px;
}
#upload .dx-button * {
  font-size: 10px !important;
}
#upload .dx-button {
  outline: none;
  border: none;
}
#upit #left_bk {
  display: flex !important;
  flex-direction: row !important;
}

#upit .i_b_bk {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px !important;
}

#upit #r_s_bk {
  display: flex !important;
  flex-direction: row !important;
}
#upit #r_s_bk p {
  margin-bottom: 1px !important;
}
#upit #r_s_bk .dx-field-label {
  display: flex !important;
  justify-content: space-between;
  padding-bottom: 50px !important;
}
#upit #r_s_bk .dx-field-value {
  margin-top: 8px !important;
}
#upit #r_s_bk {
  padding-bottom: 50px !important;
}
#bk {
  margin-bottom: 12px !important;
}
#upit #bk_n_kol {
  margin-top: 30px !important;
}
#upit #bk_n_kol .dx-fieldset {
  margin-left: 0 !important;
}
#upit .l_s_bk .mr10 {
  margin-left: 5px !important;
}
#upit .l_s_bk {
  margin-top: 8px !important;
}
#upit #naruci {
  margin-top: 1px !important;
}
#upit #s_a_btn {
  z-index: 1;
}
#upit #upload .dx-button-has-text .dx-button-content,
#upit #add_btn .dx-button-has-text .dx-button-content {
  text-align: center !important;
  padding: 7px 0 8px !important;
}
#upit #bk {
  padding: 0 !important;
}

#upit .r_c_z_k .dx-field {
  margin-top: 0 !important;
}

@media only screen and (max-width: 1600px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 9vw !important;
  }
  #upit .cijena input.dx-texteditor-input {
    width: 10vw !important;
  }
}

@media only screen and (max-width: 1500px) {
  #upit #korisnik_panel_header_u {
    margin: auto 2vw !important;
  }
  #upit #korisnik_panel_header_u #logout_btn {
    right: 15px !important;
    padding: 0 !important;
    width: auto !important;
  }
  #upit #korisnik_panel_header_u #logout_btn img {
    margin: auto 5px;
  }
  .ghi {
    margin-left: 5px !important;
    right: 4vw;
  }
  .upit-component {
    margin: auto 0;
    max-width: 80vw;
  }
  #upit .dx-field-label {
    width: 15vw !important;
    font-size: 12px !important;
  }
  #upit .dx-field-value {
    min-width: 25vw !important;
  }
  #upit .dx-field {
    min-width: 45vw !important;
  }
  .opis {
    margin-left: 5vw;
  }
  .nk_opis {
    margin-left: 5vw !important;
  }
  #upit .card {
    width: 90vw !important;
  }
  #upit .n_a .dx-field-value > div {
    margin-left: 0 !important;
  }
  #upit .cijena input.dx-texteditor-input {
    width: 24vw !important;
  }
}
@media only screen and (max-width: 1327px) {
  #boja_opis * {
    font-size: 8px;
  }
}
@media only screen and (max-width: 1092px) {
  #upit {
    height: 3350px !important;
    width: 100vw !important;
  }
  #upit .card {
    margin-left: 5vw !important;
  }
  .ghi * {
    margin-left: 0 !important;
  }
  #upit #q_div {
    right: 10px !important;
  }
  #upit .dx-box-flex {
    flex-direction: column !important;

    margin-bottom: 70px !important;
    height: 30vh;
  }
  #upit .dx-box-item {
    height: 15vh !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    height: 60px !important;
  }
  #upit .dx-item,
  #upit .dx-box-item {
    margin-top: 40px !important;
    margin-bottom: -20px !important;
  }
  #upit #upload {
    margin-left: 5px !important;
  }
  #upit .dx-field {
    width: 80vw !important;
    flex-direction: column;
    margin-bottom: 5px;
  }
  #upit .dx-field-label {
    width: 80vw;
  }
  #upit .card_p {
    height: 22vh !important;
  }
  #upit .dx-field-value,
  #upit .n_a .dx-field-value > div {
    width: 80vw !important;
  }
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 10vw;
  }
  #upit .r_p_btn .dx-button {
    margin-left: 10px !important;
  }
  #upit .materijal {
    height: 30vh !important;
  }
  #upit .materijal_opis {
    margin-top: 12.5vh !important;
  }
  #upit .materijal_opis .opis {
    width: 80vw !important;
    background-color: white;

    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
    color: black !important;
  }

  #upit .dimenzioni_kriterij {
    height: 30vh !important;
  }
  #upit .dimenzioni_kriterij_opis .opis {
    width: 80vw !important;
    background-color: white;
    margin-top: 9vh !important;

    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
  }
  #upit .dimenzioni_kriterij_opis .opis * {
    color: black !important;
  }
  #upit .nacin_kacenja {
    height: 70vh !important;
  }
  #upit .nacin_kacenja_opis {
    margin-top: 14.8vh;
  }
  #upit .nacin_kacenja_opis .opis {
    width: 80vw !important;
    background-color: white;
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
  }
  #upit .nacin_kacenja_opis .opis li {
    margin-top: 5px;
  }
  #upit .nacin_kacenja_opis .opis * {
    color: black !important;
  }
  #upit .nacin_povr_zast {
    height: 40vh !important;
  }
  #upit .nacin_povr_zast_opis {
    margin-top: 3.4vh;
  }
  #upit .nacin_povr_zast_opis .opis {
    width: 80vw !important;
    margin-top: 0 !important;
    background-color: white !important;
  }
  #upit .nacin_povr_zast_opis .npz_opis {
    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
  }
  #upit .nacin_povr_zast_opis .opis * {
    color: black !important;
  }
  #upit .nacin_povr_obr {
    height: 50vh !important;
  }
  #upit .nacin_povr_obr_opis .opis {
    width: 80vw !important;
    margin-top: -1.6vh !important;
    background-color: white !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 5px;
    border-radius: 5px;
    color: black !important;
  }

  #upit .nacin_povr_obr_opis .opis * {
    color: black !important;
  }
  #upit .debljina_oks_sloja {
    height: 25vh !important;
  }
  #upit .debljina_oks_sloja_opis {
    margin-top: 0vh;
  }
  #upit .debljina_oks_sloja_opis .list-opis-hppe {
    width: 80vw !important;
    margin-top: 15.2vh !important;
    background-color: white !important;
    margin-left: 0 !important;
    margin-right: 10px !important;
    padding: 10px;
    border-radius: 5px;
    color: black !important;
  }

  #upit .debljina_oks_sloja_opis .list-opis-hppe * {
    color: black !important;
  }
  .geom-tijelo {
    width: 18vw !important;
  }
  .g_tijela {
    width: 100% !important;
  }
  #modal-left-container {
    width: 50vw !important;
  }
  #modal-left-container-inputs,
  #float-buttons {
    margin-left: 50px;
  }
  .popup-item {
    margin-left: 50px;
  }
  #tabela-povrsina-2 .dx-datagrid {
    width: 30vw !important;
  }
  #upit * {
    font-size: 12px;
  }
  #upit input,
  #upit div.dx-item-content .dx-list-item-content {
    font-size: 10px;
  }

  #npz_opis *,
  #boja_opis * {
    font-size: 8px;
  }
  #npz_opis,
  #boja_opis {
    background-color: white;
  }
  #upit .povrsina .dx-field-label .naziv-geom-tijela {
    width: 10vw !important;
  }

  #upit #u_i_b,
  #upit #u_b > div,
  #upit #left_bk,
  #upit #r_s_bk {
    flex-direction: column !important;
  }

  #upit #r_s_bk .dx-field-label {
    width: 80vw !important;
  }
  #upit #r_s_bk .dx-texteditor-container {
    width: 60vw !important;
  }

  #upit #add_btn .dx-button {
    margin-bottom: 10vh !important;
  }
  #upit #add_btn .dx-button,
  #upit #add_btn .dx-button-has-text .dx-button-content {
    width: 60vw !important;
  }
  #upit h1,
  #upit .h1 {
    width: 100% !important;
  }
  #upit .d_n_k .dx-button {
    margin-top: 160px !important;
  }
  #upit #n .dx-button {
    margin-top: 50px !important;
    margin-left: 0 !important;
    width: 78vw !important;
  }
  #upit .card_bk_rc {
    padding-bottom: 21vh !important;
  }
  #upit #close {
    position: absolute;
    right: 16.2vw !important;
    width: 40px !important;
  }
  #upit #calc {
    width: 37px !important;
    padding: 0 !important;
    font-size: 14px;
  }
  #upit .cijena {
    width: 60vw !important;

    margin-top: -50px !important;
  }
  #upit .l_s_bk .i_b_bk {
    width: 10vw !important;
  }
  #upit #bk {
    height: 30vh;
  }
  #upit .label {
    width: 100% !important;
  }
  #upit .card_sa {
    height: 20vh !important;
  }
}
@media only screen and (max-width: 1040px) {
  #upit #number p {
    font-size: 16px;
  }
  #korisnik_panel_header_u {
    height: 70px;
  }
}

@media only screen and (max-width: 900px) {
  #korisnik_panel_header_u #logout_btn {
    top: 9px !important;
  }
  #korisnik_panel_header_u #logout_btn img {
    height: 15px;
  }
}
@media only screen and (max-width: 785px) {
  .s_a_label * {
    font-size: 10px;
  }
  .slijepe_rupe * {
    font-size: 10px;
  }
}
@media only screen and (max-width: 622px) {
  #upit #close {
    right: 16.5vw !important;
  }
}
@media only screen and (max-width: 587px) {
  #upit #napomena {
    font-size: 10px;
  }
  #SI,
  #ZL,
  #TI,
  #CV,
  #PL,
  #ZE,
  #NA,
  #CR {
    width: 3vw;
  }
}
@media only screen and (max-width: 558px) {
  #upit #number p {
    font-size: 12px;
  }
  #korisnik_panel_header_u {
    height: 65px;
  }
}
@media only screen and (max-width: 489px) {
  #upit * {
    font-size: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .r_p_btn div div .dx-button-content {
    font-size: 10px;
  }
  #upit #napomena {
    font-size: 9px;
  }
  #upit * {
    font-size: 10px;
  }
}

@media only screen and (max-width: 461px) {
  #upit * {
    font-size: 8px;
  }
  #SI,
  #ZL,
  #TI,
  #CV,
  #PL,
  #ZE,
  #NA,
  #CR {
    width: 4vw;
  }
}
@media only screen and (max-width: 445px) {
  #korisnik_panel_header_u #number {
    margin-right: 65px !important;
  }
}
@media only screen and (max-width: 421px) {
  #upit * {
    font-size: 8px;
  }
}
@media only screen and (max-width: 414px) {
  #upit * {
    font-size: 7px;
  }
  #korisnik_panel_header_u #number {
    margin-right: 55px !important;
  }
}
@media only screen and (max-width: 403px) {
  #upit * {
    font-size: 7px;
  }
  #SI,
  #ZL,
  #TI,
  #CV,
  #PL,
  #ZE,
  #NA,
  #CR {
    width: 6vw;
  }
}
#cijena_footer {
  margin: 2px 0 auto;
  max-width: 60vw;
}
@media only screen and (max-width: 398px) {
  #korisnik_panel_header_u #number {
    margin-right: 54px !important;
  }
}
@media only screen and (max-width: 390px) {
  #korisnik_panel_header_u #number {
    margin-right: 55px !important;
  }
  #korisnik_panel_header_u #number p {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 388px) {
  #upit #close {
    right: 17vw !important;
  }
}
@media only screen and (max-width: 350px) {
  #upit #close {
    right: 15vw !important;
  }
}
@media only screen and (max-width: 320px) {
  #upit #close {
    right: 12vw !important;
  }
}
/*
@media only screen and (max-width: 1092px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 180px;
  }
}
@media only screen and (max-width: 1327px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 160px;
  }
}
@media only screen and (max-width: 1247px) {
  #upit .n_b div.dx-texteditor-input-container,
  #upit .n_b input.dx-texteditor-input {
    width: 140px;
  }
}
*/

@media only screen and (max-width: 1371px) {
  .upit-component {
    margin: auto 0;
    max-width: 90vw !important;
  }
  .card {
    width: 90vw;
  }

  #upit .r_p_btn {
    right: 40vw;
    width: auto;
  }
}
#upit .bk_input_row {
  width: 50vw !important;
}
#upit .bk_input_row .dx-field-value {
  width: 10vw !important;
}
#upit .dx-field-label .bk_label {
  width: 0 !important;
}

#upit .n_b_c div.dx-texteditor-input-container,
#upit .n_b_c input.dx-texteditor-input {
  text-align: right !important;
}
#upit .u_i_b_c .n_b {
  width: 10vw;
}
#upit .u_i_b_c .dx-button {
  margin-left: 10px !important;
}
#upit #narudzba_div {
  width: 50% !important;
}

#upit #s_a_btn > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}
@media only screen and (max-height: 796px) {
  #upit .card_p,
  #upit .card_sa {
    padding-bottom: 10vh !important;
    height: 30vh !important;
    margin-bottom: 3vh !important;
  }
  #upit .card_p #upit .r_p_btn {
    padding-bottom: 10vh !important;
  }

  #upit .card_bk_rc {
    padding-bottom: 31vh !important;
  }
}
@media only screen and (max-height: 750px) {
  #upit #bk {
    height: 35vh !important;
  }
}
@media only screen and (max-height: 670px) {
  #upit #bk {
    height: 40vh !important;
  }
}
@media only screen and (max-height: 604px) {
  #upit .card_bk_rc {
    padding-bottom: 41vh !important;
  }
  #upit #bk {
    height: 42vh !important;
  }
}
@media only screen and (max-height: 574px) {
  #upit .card_p,
  #upit .card_sa {
    height: 35vh !important;
  }
  #upit .card_bk_rc {
    padding-bottom: 51vh !important;
  }
  #upit #bk {
    height: 50vh !important;
  }
}
@media only screen and (max-height: 492px) {
  #upit .card_p,
  #upit .card_sa {
    height: 37vh !important;
  }
  #upit .card_bk_rc {
    padding-bottom: 61vh !important;
  }
  #upit #bk {
    height: 55vh !important;
  }
}
@media only screen and (max-height: 400px) {
  #upit .card_bk_rc {
    padding-bottom: 71vh !important;
  }
  #upit #bk {
    height: 60vh !important;
  }
}
#upit h1,
#upit .h1 {
  width: 100% !important;
}

@media only screen and (max-width: 1570px) {
  .card_sa > div {
    display: flex;
    flex-direction: column !important;
  }
  .card_sa .dx-button {
    margin-left: 27vw !important;
  }
}
