.container {
  position: relative;
}

.container,
.left-content {
  min-height: 530px;
}

.left-content {
  display: inline-block;
  width: 180px;
  padding: 0 10px 10px;
  background-color: rgba(248, 255, 253, 0.15);
  box-shadow: -5px 0px 14px -8px rgba(0, 0, 0, 0.25) inset;
}

.right-content {
  position: absolute;
  right: 0;
  left: 220px;
  top: 0;
  height: 100%;
}

sup {
  font-size: 0.8em;
  vertical-align: super;
  line-height: 0;
}

.right-content .sub-title {
  font-size: 120%;
  color: rgba(152, 152, 152, 0.8);
}

.title-container {
  min-height: 140px;
  margin-bottom: 10px;
}

.title-container .country-name {
  font-size: 240%;
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 10px;
}

.title-container > div:not(.flag) {
  margin-left: 204px;
}

.stats {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}

.stats > div {
  display: table-cell;
  text-align: center;
  border: 1px solid rgba(191, 191, 191, 0.25);
  padding: 20px 0 25px;
  width: 33%;
}

.stats > div:first-child,
.stats > div:last-child {
  border-right-width: 0;
  border-left-width: 0;
}

.stats .stat-value {
  font-size: 200%;
}

#tabpanel {
  margin-top: 10px;
}

#tabpanel .dx-multiview-wrapper {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

#tabpanel .tab-panel-title {
  font-size: 120%;
  font-weight: 500;
}

#tabpanel .dx-multiview-item-content {
  padding: 20px 0 22px;
  min-height: 178px;
  position: relative;
}

#tabpanel .right-content {
  top: 15px;
  left: 202px;
}

#tabpanel .stats {
  width: 398px;
  margin-top: 20px;
  border-top: 1px solid rgba(250, 246, 246, 0.25);
}

#tabpanel .stats > div {
  padding: 7px 0;
  text-align: left;
  border: 0;
}

#tabpanel .stats > div:first-child {
  width: 40%;
}

#tabpanel .stats > div:not(:first-child) {
  width: 30%;
}

.left-content
  .dx-treeview-node.dx-state-selected
  > .dx-item
  > .dx-item-content {
  font-weight: 500;
}

#admin_panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
  z-index: -99;
}
.light-mode #admin_panel {
  background-image: linear-gradient(
    to bottom right,
    rgb(252, 252, 252),
    rgb(223, 217, 220)
  );
}
.dark-mode #admin_panel {
  background-image: linear-gradient(
    to bottom right,
    rgb(20, 19, 19),
    rgb(75, 74, 74)
  );
}
#admin_panel_header {
  position: fixed;
  display: block;
  width: 100vw;
  height: 60px;
  z-index: 10;
  background: rgba(243, 239, 239, 0.2);
  backdrop-filter: blur(12.5px);
}

.container {
  position: absolute;
  top: 60px;
}
.left-content {
  height: 126vh;
  padding-left: 0;
  position: absolute;
  left: 0;
  background-color: white;
}
.dark-mode .left-content {
  background: rgba(243, 239, 239, 0.2);
  -webkit-backdrop-filter: blur(12.5px);
  backdrop-filter: blur(12.5px);
}

.light-mode #admin_panel #gridDeleteSelected {
  opacity: 1;
  background-color: white;
  color: black;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 2px;
}
.dark-mode #admin_panel #gridDeleteSelected {
  background-color: #333 !important;
}
#admin_panel span.dx-button-text {
  opacity: 1;
}

#admin_panel
  .dx-treeview-item-without-checkbox.dx-state-focused
  > .dx-treeview-item {
  background-color: black;
}

@media only screen and (max-width: 767px) {
  #admin_panel div.dx-button-content {
    width: 30vw;
    background-color: white;
    font-size: 12px;
  }
}

@media only screen and (max-width: 754px) {
  #admin_panel * {
    font-size: 12px;
  }
}

@media only screen and (max-width: 613px) {
  #admin_panel * {
    font-size: 8px;
    width: 30vw;
  }
}
