.lista_podataka {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.lista_podataka div {
  display: flex;
  justify-content: space-between;
}

#profil_podaci {
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
}

.chng_pwd_btn div.dx-button {
  margin-top: 8px;
  margin-left: 8px;
  background-color: black;
  color: white;
}
#admin_profil {
  width: 100%;
}
#card {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  background-color: white;
}
.dark-mode #admin_profil #card {
  background-color: #464444;
}
