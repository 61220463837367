.sgfsgd {
  width: 475px;
  height: 648px;
}

#header,
#footer {
  margin: 0;
  padding: 0;
  width: 475px;
}
#header {
  margin-bottom: 30px;
}
#footer {
  margin-top: 20px;
}
#adresa {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 90px;
}
#adresa {
  width: 229px;
}
#adresa-1 {
  height: 82px;
}
#adresa-1 h3 {
  font-size: 13px;
  text-align: left;
  width: 140px;
  margin-top: 0;
  margin-bottom: 0;
}
#adr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 475px;
}

#adresa-3 {
  width: 50%;
  height: 94px;
  border: 1px solid black;
}

#adresa-3-1 {
  border-bottom: 1px solid black;
  display: flex;
}

#adresa-3-1-1 {
  margin-left: 5px;
  width: 50%;
}

#adresa-3-1-1-1 {
  font-size: 14px;
  font-weight: 600;
}
#adresa-3-1-2 {
  text-align: center;
  width: 50%;
  font-size: 13px;
}
#adresa-3-2-1 {
  width: 50%;
}
#adresa-3-2-1 * {
  margin-left: 2px;
  font-size: 12px;
}

#adresa-3-2-1-2,
#adresa-3-2-1-3 {
  font-weight: 400;
}

#adresa-3-2 {
  display: flex;
}

#adresa-3-2-2 {
  width: 50%;
  text-align: center;
  margin-top: 1px;
  padding-bottom: 1px;
  font-size: 14px;
}

#narudzba-1-1 * {
  font-weight: 700;
  font-size: 10px;
  width: 100px;
}
#narudzba-1-2 * {
  font-weight: 700;
  font-size: 10px;
  width: 320px;
}
#narudžba-1 {
  display: flex;
  justify-content: flex-start;
}

#narudzba-1-1 {
  margin-right: 40px;
}

#narudzba p {
  font-weight: 600;
}
#narudzba b {
  font-size: 13px;
  border-bottom: 1px solid black;
}

#pozdrav * {
  font-size: 8px;
  font-weight: 600;
  width: 340px;
}
#pozdrav p {
  margin-bottom: 16px;
}
#tabela-report .dx-datagrid {
  width: 475px;
  font-size: 6px;
}
.sgfsgd {
  margin-left: 5px;
}
#adresa-3-1-2 {
  margin-top: 5px;
}
#adresa-2 {
  display: flex;
  justify-content: space-around;
  width: 115px;
}
.adresa-2-1 {
  display: flex;
  justify-content: space-between;
}
.adresa-2-1 * {
  width: 120px;
  margin-left: 8px;
}
/*
#adresa-1 {
  width: 126px;
  font-weight: 700;
  font-size: 18px;
}

#adresa-2 {
  display: flex;
  justify-content: space-around;
  width: 115px;
}
#adresa {
  width: 25vw;
  height: 100px;
}

.adresa-2-1 * {
  font-weight: 500;
  font-size: 12px;
}
#pdf {
  padding-left: 45px;
  height: 100%;
  width: 100%;
}
#pdf * {
  font-family: Arial;
}

#adr {
  display: flex;
  flex-direction: row;
  width: 229.5px;
}
#tabela-report .dx-datagrid {
  width: 229.5px;
  font-size: 8px;
}
#adresa-3 {
  width: 25vw;
  height: 16vh;
  border: 2px solid black;
}

#adresa-3-1 {
  border-bottom: 2px solid black;
  display: flex;
}

#adresa-3-1-1 {
  margin-left: 5px;
  width: 50%;
}

#adresa-3-1-1-1 {
  font-size: 20px;
  font-weight: 600;
}
#adresa-3-1-2 {
  text-align: center;
  width: 50%;
  font-size: 22px;
}
#adresa-3-2-1 {
  width: 50%;
}
#adresa-3-2-1 * {
  margin-left: 5px;
  font-size: 16px;
}

#adresa-3-2-1-2,
#adresa-3-2-1-3 {
  font-weight: 400;
}

#adresa-3-2 {
  display: flex;
}

#adresa-3-2-2 {
  width: 50%;
  text-align: center;
  margin-top: 2vh;
  padding-bottom: 2vh;
  font-size: 20px;
}

#narudzba-1-1 *,
#narudzba-1-2 *,
#narudzba p {
  font-weight: 700;
  font-size: 12px;
  width: 10vw;
}

#narudžba-1 {
  display: flex;
  justify-content: flex-start;
}

#narudzba-1-1 {
  margin-right: 40px;
}
.dx-datagrid-headers {
  color: black;
  font-weight: bold;
}

.dx-datagrid .dx-row > td {
  font-family: Arial, Helvetica, sans-serif;
}

.dx-datagrid {
  width: 80vw;
}

#narudzba p {
  font-weight: 600;
}
#narudzba b {
  font-size: 18px;
  border-bottom: 1px solid black;
}

#pozdrav * {
  font-size: 12px;
  font-weight: 600;
  width: 229.5px;
}

#adresa h3 {
  margin-top: 0;
}

.whsp {
  margin-left: 5px;
}

#adresa-3-1-1-2 {
  font-size: 16px;
  font-weight: 600;
}

#pdf * {
  font-family: "Arial";
}

#adresa-3-1-2-1 {
  font-size: 18px;
  margin-left: 15px;
  text-align: start;
}


.report-cell-render {
  list-style-type: none;
  padding: 0;
  text-align: start;
}
*/
