#register_wait {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute !important;
  top: 0 !important;
  width: 100vw !important;
  left: 0 !important;
  height: 100vh !important;
}

#register_wait_images_wrapper {
  width: 55%;
  height: 80%;
}
#register_wait_images_wrapper {
  display: flex;
  justify-content: flex-start;
  padding-right: 0;
  padding-bottom: 0;
}
#register_wait_image_container_1 {
  margin-right: 20px;
  width: 40vw;
}

#register_wait_image_container_2 {
  margin-top: 10vh !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#register_wait_image_container_2 > div {
  display: flex;
  flex-direction: column;
}

#image3 {
  z-index: 1;
  margin-top: 45px;
}

#image1,
#image2,
#image3 {
  height: 90%;
}
#image2 {
  max-width: 100%;
  width: 500px;
  vertical-align: middle;
  margin-top: 40px;
  opacity: 0.8982;
  transform: translate(0%, 1.0176%) translate3d(0px, 0px, 0px);
}
#register_wait_text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#register_wait_text_container h1 {
  padding: 40px;
  font-weight: 700;
}
#register_wait_text {
  text-align: justify;
  padding: 40px;
  font-size: 16px;
}

@media only screen and (max-width: 1000px) {
  #register_wait img {
    width: 250px;
  }
  #register_wait_text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 770px) {
  #register_wait img {
    width: 200px;
  }
}

@media only screen and (max-width: 645px) {
  #register_wait img {
    width: 150px;
  }
  #register_wait_images_wrapper {
    width: 50%;
  }
}
@media only screen and (max-width: 555px) {
  #register_wait img {
    width: 120px;
  }
  #register_wait h1 {
    margin-bottom: -50px;
  }
}
@media only screen and (max-width: 521px) {
  #register_wait {
    flex-direction: column;
  }
  #register_wait_images_wrapper {
    margin-top: 50px;
  }
  #register_wait_images_wrapper img {
    width: 70vw;
    height: 25vh;
    margin-right: 10vw;
  }
  #register_wait_image_container_1,
  #register_wait #image3 {
    height: 0;
    width: 0;
  }
  #register_wait h1,
  #register_wait_text {
    margin: -20px;
  }
}
