@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

* {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
.dx-field-label,
.dx-field-value {
  font-size: 18px;
}

body {
  margin: 30px;
}

form {
  margin: 10px;
}

#logout_btn {
  position: absolute;
  right: 18vw;
  top: 7px;
  z-index: 9999;
  border: none;
  outline: none;
  background-color: transparent;
}
#admin_panel_lu {
  margin-left: 15vw;
}
.register-login {
  margin-right: 13vw;
  display: flex;
  justify-content: flex-end;
}
.register-login * {
  margin-left: 70px;
  margin-right: 2px;
  width: 20px;
  height: 10px;
  list-style-type: none;
}

.languages * {
  margin-left: 5px;
  width: 30px;
  height: 15px;
  list-style-type: none;
  /*position: absolute;*/
  margin-right: 10px;
}
.nav-lng {
  display: flex;
  flex-direction: row !important;
  justify-content: flex-end;
}
.languages {
  margin-right: 45px;
  top: 25px;
  position: fixed;
  z-index: 999999;
  right: 22vw;
}
#ul_lng {
  display: flex;
}
.dx-field {
  margin-top: 50px;
}

.select-with-image div {
  display: inline-flex;
  width: 90%;
}

.select-with-color {
  display: flex;
  justify-content: flex-start;
}

.product-name {
  width: 80%;
}
.image-field {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.images {
  margin-left: 5px;
  margin-top: 2px;
  height: 30px;
  width: 30px;
}
#SI {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url('./Images/natur.JPG');
}

#ZL {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url('./Images/zlatna.JPG');
}

#TI {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url('./Images/titan.JPG');
}
#CV {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url('./Images/crvena.JPG');
}

#PL {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url('./Images/plava.JPG');
}

#ZE {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;

  background: url('./Images/zelena.jpg');
}

#NA {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url('./Images/narandzasta.JPG');
}

#CR {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background: url('./Images/crna.JPG');
}

.povrsina {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.dx-field-label {
  width: auto;
}
.povrsina div {
  display: flex;
}
label {
  margin: 8px 5px auto;
  color: rgb(51, 122, 183);
}

.error {
  position: absolute;
  margin-left: 30vw;
  text-align: center;
  font-size: 40px;
  color: rgb(12, 77, 88);
}

.dobijenaCijena {
  display: flex;
}

.list-opis {
  list-style-type: none;
  padding-left: 3px !important;
}
.list-opis-hppe {
  list-style-type: none;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-around;
  font-size: 14px;
  height: 12px;
  width: 25vw !important;
}
.list-opis-hppe li {
  margin-left: 1px;
}
.opis,
.list-opis-hppe {
  text-align: left;
  margin-top: 1px;
}
.opis {
  font-size: 14px;
}
.boja-opis {
  margin-left: -50px;
}
.btns {
  width: 100vw;
  height: 30px;
}

.star {
  color: red;
}

.switch-div {
  display: flex;
  justify-content: flex-end;
}

#error_page h1,
h2 {
  font-family: 'Cinzel', serif;
  color: rgb(165, 116, 9);
}

#error h1,
h2 {
  font-family: 'Cinzel', serif;
  color: rgb(165, 116, 9);
}

.cijena-component {
  margin: auto 20vw;
}
.upit-component {
  padding-bottom: 5px;
}

.list-upit {
  list-style-type: none;
}
.list-upit * {
  padding: 15px;
  font-size: 16px;
}
.list-upit h2 {
  font-family: 'Cinzel', serif;
  font-size: 20px;
  color: rgb(48, 61, 87);
  border-bottom: 1px solid gold;
}
.list-upit-details li {
  color: black;
}

.dx-tabs .dx-tab,
.dx-tabs .dx-button-content {
  background-color: rgb(253, 255, 255);
}

#go-to-upit {
  float: right;
  height: 5vh;
  width: 10vw;
  font-size: 18px;
}

.cijena-prepare {
  height: 30vh;
}

.silings * {
  margin-left: 10px;
}

.home-page * {
  text-align: center;
  font-family: 'Cinzel', serif;
  font-size: 40px;
  color: rgb(48, 61, 87);
  border-bottom: 1px solid gold;
}

.dx-datagrid-headers {
  color: black;
  font-weight: bold;
}

.dx-datagrid .dx-row > td {
  font-family: Arial, Helvetica, sans-serif;
}

.dx-datagrid {
  width: 80vw;
}

.dx-dropdowneditor-icon {
  height: 1000%;
}
@media only screen and (max-width: 1700px) {
  #admin_panel_lu {
    margin-left: 0;
  }
}
@media only screen and (max-width: 900px) {
  .dx-field-label,
  .dx-field-value {
    font-size: 12px;
  }
  #logout_btn {
    top: 2vh;
  }
  .languages li img {
    margin-left: 2px;
    width: 25px;
    height: 10px;
  }
  h1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  h1 {
    font-size: 16px;
  }
  #logout_btn {
    top: 2vh;
    padding-left: 0;
  }
}
#checkSelectAll.dx-checkbox-text {
  color: red;
}

.dx-checkbox-text {
  font-size: 16px;
  padding: 0;
  padding-left: 25px;
}

.list-group {
  display: flex;
  flex-direction: column;
}

.list-group-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.list-group-item * {
  width: 50%;
}

.image {
  margin-left: 10px;
  margin-top: 8px;
  height: 2vh;
  width: 2vw;
  background-color: rgb(0, 255, 0);
}

.k-button {
  color: white;
  background-color: rgb(51, 122, 183);
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px 18px 8px;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}

#modal-povrsina {
  display: flex;
  justify-content: space-between;
}
#tabela-povrsina {
  width: 100% !important;
  margin-right: 20px;
}

.naziv-geom-tijela {
  font-size: 14px;
}
#tabela-povrsina {
  width: 40vw;
}
#tabela-povrsina-1 {
  display: flex;
}

.adminPanelGrid .dx-datagrid {
  width: 60vw;
}

#draw {
  margin: 30px;
  width: 100%;
  height: 100%;
}
#draw input {
  font-size: 18px;
}
#draw Button {
  margin-top: 15px;
  margin-left: 15px;
  font-size: 12px;
}
.custom-icon {
  width: 20vw;
  height: 17vh;
}
.neispravni_podaci {
  color: #d9534f;
  border-bottom: 1px dashed #d9534f;

  width: max-content;
}

/*lightbox */
.lightbox-btn-close {
  left: auto !important;
  right: 0 !important;
  width: 85px !important;
}
.lightbox-btn-close svg {
  width: 75px !important;
  height: 75px !important;
}
.lightbox-btn-close svg path {
  d: path(
    'M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z'
  ) !important;
}

.opis {
  width: 25vw !important;
}

.dos_list {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 25vw;
}
/*

.dx-datagrid-content .dx-datagrid-table .dx-row > td,
.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td {
  vertical-align: middle !important;
}
*/

/* 23.06. */
.np_opis {
  background-color: white;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}

#uslovi_app {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}
/*dark mode */
.react-switch-bg {
  background: rgb(136, 136, 136) !important;
}
.dark-mode,
.dark-mode #login,
.dark-mode #register,
.dark-mode #korisnik_panel,
.dark-mode #lista_upita .dx-datagrid {
  background-color: #353434;
}
.dark-mode #upit,
.dark-mode #s_a,
.dark-mode #error_page,
.dark-mode #error,
.dark-mode #uslovi_app {
  background: linear-gradient(
      to bottom,
      rgba(10, 10, 10, 0.6),
      rgba(0, 0, 0, 100)
    ),
    url('./Images/Background.png') !important;
  background-size: cover;
  width: 100% !important;
  z-index: -99;
}

#app {
  width: 100vw;
  height: 100vh;
}

#app .dark-mode {
  background-color: black;
}
.light-mode #upit,
.light-mode #s_a,
.light-mode #lista_upita .dx-datagrid,
.light-mode #error_page,
.light-mode #error,
.light-mode #uslovi_app {
  background: linear-gradient(
      to bottom,
      rgba(205, 205, 205, 0.5),
      rgba(205, 205, 205, 0.7)
    ),
    url('./Images/Background.png') !important;
  width: 100% !important;
  height: 100% important;
  background-size: cover;
}
.light-mode #s_a {
  width: 100vw !important;
  height: 100% !important;
}
.dark-mode .np_opis {
  background-color: #353434;
}
.dark-mode #upit .card,
.dark-mode #s_a .card,
.dark-mode #upit .dx-checkbox,
.dark-mode #upit .dx-checkbox .dx-checkbox-icon,
.dark-mode #register .dx-checkbox,
.dark-mode #register .dx-checkbox .dx-checkbox-icon,
.dark-mode
  #register
  div.dx-form-group-with-caption.dx-form-group.dx-group-colcount-1 {
  background-color: #353434;
}
.dark-mode
  #register
  div.dx-form-group-with-caption.dx-form-group.dx-group-colcount-1 {
  box-shadow: 0 0 3px rgba(250, 250, 250, 0.4);
}
.dark-mode #s_a {
  position: absolute;
  width: 100vw;
  height: 1050px;
}
.dark-mode #s_a h2 {
  color: white !important;
}

.dark-mode #upit {
  height: 1800px;
}
.modal_shown {
  height: 2300px;
}
.dark-mode #upit {
  border-bottom: 130px black solid;
}
.dark-mode * {
  color: white;
}
.dark-mode .dx-texteditor-input,
.dark-mode .dx-show-invalid-badge {
  background-color: #464444;
}
.dark-mode .dx-texteditor-buttons-container {
  background-color: #464444;
}
.dark-mode #login-form-btn-submit {
  background-color: #07377c;
}

.dark-mode .login-textbox {
  outline: none !important;
}
.dark-mode #register-form {
  margin-left: -10vw;
}

.dark-mode #register-form form {
  margin-top: 40px !important;
}
.dark-mode #register-image-absolute {
  height: 55% !important;
}
.dark-mode #lista_upita .dx-datagrid .dx-row-alt > td,
.dark-mode #lista_upita .dx-datagrid .dx-row-alt > tr > td {
  background-color: rgb(85, 85, 88);
  border-color: rgb(85, 85, 88);
}

.dark-mode .dx-datagrid,
.dark-mode #register_wait {
  background-color: #39383b;
}

.light-mode .dx-datagrid .main-row {
  background-color: #b8b7b9 !important;
}
.dark-mode
  #lista_upita
  div.dx-datagrid-headers
  .dx-datagrid-table
  .dx-row.dx-header-row
  td {
  background-color: #07377c;
  color: white;
  border-bottom: none;
}
.dark-mode .blur {
  background-color: rgba(205, 205, 205, 0.3);
}

.dark-mode #k_p_h,
.dark-mode #k_p_h_u,
.dark-mode #k_p_h_sa {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(12.5px);
  -webkit-backdrop-filter: blur(8px);
  box-shadow: inset 0 0 40px 15px hsl(0deg 0% 100% / 20%);
}

.dark-mode #lista_upita #gridContainer tbody.employee:hover {
  background-color: #39383b;
}
.dark-mode #register .dx-button {
  background-color: #07377c;
}
.dark-mode .dx-button,
.dark-mode .k-button {
  border: 0.3px solid white !important;
}
.opis *,
.m_opis {
  font-weight: 400;
}
.opis b {
  font-weight: 800;
}

.light-mode .progress * {
  color: black !important;
}
.progress-bar {
  background-color: #07377c !important;
}
.dark-mode div.progress {
  background: transparent !important;
}
.dark-mode #upit .slijepe_rupe {
  color: white;
}

.dark-mode #eye-icon-btn div.dx-button {
  border: none !important;
}

.dark-mode #back {
  color: white !important;
}

/* za sliku ako bude dodana kao pozadina upit komponente
.light-mode #upit {
  background: url('./Images/Jednostavno/j2.JPG');
  position: cover;
}*/
#q {
  color: white;
}
.star {
  color: red !important;
}
/*
.dark-mode #lista_upita .dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
  background-color: rgb(85, 85, 88);
  border-color: rgb(85, 85, 88);
}

.dark-mode .dx-datagrid {
  background-color: #39383b;
}

.dark-mode
  #lista_upita
  div.dx-datagrid-headers
  .dx-datagrid-table
  .dx-row.dx-header-row
  td {
  background-color: #07377c;
  color: white;
  border-bottom: none;
}
*/
.dark-mode .dx-overlay-content,
.dark-mode .dx-toolbar {
  background-color: #353434 !important;
  color: white !important;
}
.dark-mode .dx-overlay-content div.dx-button-content * {
  color: white;
  background-color: #353434 !important;
}
.dark-mode .dx-closebutton {
  outline: none !important;
  border: none !important;
}
.light-mode .dx-overlay-content div.dx-button-content * {
  outline: none !important;
  border: none !important;
  background-color: white !important;
}
.dark-mode .rgst_vldt * {
  color: black !important;
}
.rgst_vldt .dx-button-content * {
  color: white !important;
}

.dark-mode #btn-icon {
  background: transparent !important;
}
.dark-mode
  .dx-field-item-content
  .dx-field-item-content-location-right
  .dx-button {
  background-color: transparent !important;
}
.abc {
  right: 9vw;
  margin-right: 50px !important;
}
.abc li {
  margin-right: 0 !important;
}
.def {
  right: 7vw;
  margin-right: 0 !important;
}
.def li {
  margin-right: 0 !important;
}

.dx-checkbox-icon,
.dx-select-checkboxes-hidden {
  border: 1px rgb(165, 165, 165) solid !important;
}

#upload_draw .dx-toolbar-label {
  max-width: 100% !important;
}

#upload_draw .dx-button-content {
  padding-left: 5vw !important;
}
