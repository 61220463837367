#tabela-prevoda .dx-datagrid {
  width: 100%;
}

#lng_form > div {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  background-color: white;
  width: 45%;
}
.dark-mode #lng_form > div {
  background-color: #464444;
  border: 1px solid white;
}
#lng_form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#lng_img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px dotted gray;
}
#lng_img > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#new_lng_btn,
#end_lng_btn {
  float: right;
  background-color: black;
  color: white;
}
#jezik {
  width: 100%;
}
/*
#tabela-prevoda div.dx-datagrid-header-panel,
#tabela-prevoda
  .dx-toolbar
  .dx-widget
  .dx-visibility-change-handler
  .dx-collection,
#tabela-prevoda .dx-toolbar-items-container {
  background-image: linear-gradient(
    to bottom right,
    rgb(252, 252, 252),
    rgb(223, 217, 220)
  );
}
*/
.dx-editor-cell .dx-texteditor,
.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  background: transparent !important;
}
